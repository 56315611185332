import { createReducer } from '@reduxjs/toolkit'
import _ from 'lodash'

import { fetchDevicesList, deleteDevice } from '../actions/devices.actions'

const initialState = {
  list: {
    loading: false,
    data: [],
    total: 0,
  },
  deletingDevice: false,
}

const devicesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchDevicesList.pending, (state, action) => {
      state.list.data = []
      state.list.loading = true
      state.list.total = 0
    })
    .addCase(fetchDevicesList.fulfilled, (state, action) => {
      state.list.data = action.payload.list || action.payload || []
      state.list.total = action.payload.total
      state.list.loading = false
    })
    .addCase(deleteDevice.pending, (state, action) => {
      state.deletingDevice = true
    })
    .addCase(deleteDevice.fulfilled, (state, action) => {
      const deletedIndex = _.findIndex(state.list.data, ['_id', action.payload])

      // Remove deleted from list
      state.list.data.splice(deletedIndex, 1)

      state.deletingDevice = false
    })
    .addCase(deleteDevice.rejected, (state, action) => {
      state.deletingDevice = false
    })
})

export default devicesReducer
