import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import { Space, Popconfirm, Badge } from 'antd'

import STATUS_TYPES from '../../constants/licensesStatusTypes'
import {
  fetchLicensesList,
  approveLicense,
  revokeLicense,
} from '../../store/actions/licenses.actions'
import useAuth from '../../hooks/useAuth'
import useTable from '../../hooks/useTable'
import FilterDropdown from '../../components/FilterDropdown'
import TableContainer from '../../components/TableContainer'
import CustomerTag from '../../components/Customer/CustomerTag'

const Licenses = () => {
  const dispatch = useDispatch()
  const { queryStringDefaults, getQueryObject } = useTable()
  const { isSuperUser } = useAuth()
  const [
    changeApprovalStatusPopconfirmKey,
    setChangeApprovalStatusPopconfirmKey,
  ] = useState(null)
  // const { getColumnSearchProps, getAlphabeticalSorterProps } = useTableActions()
  const [
    licensesList,
    approvingLicense,
    revokingLicense,
  ] = useSelector((state) => [
    state.licenses.list,
    state.licenses.approvingLicense,
    state.licenses.revokingLicense,
  ])

  const changeApprovalStatusHandler = (id, status) => {
    let promise
    if (status === STATUS_TYPES.APPROVED) {
      promise = dispatch(revokeLicense({ id }))
    } else {
      promise = dispatch(approveLicense({ id }))
    }

    promise.then(() => setChangeApprovalStatusPopconfirmKey(null))
  }

  useEffect(() => {
    dispatch(fetchLicensesList(queryStringDefaults))
  }, [dispatch, queryStringDefaults])

  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      filterDropdown: (props) => <FilterDropdown {...props} />,
      defaultFilteredValue: queryStringDefaults.email || [],
      sorter: true,
    },
    {
      title: 'Customer',
      dataIndex: 'customer',
      className: isSuperUser ? null : 'fb-column-hidden',
      render: (customer) => <CustomerTag name={customer.name} />,
    },
    {
      title: 'Device',
      dataIndex: ['device', 'name'],
    },
    {
      title: 'Version',
      dataIndex: 'ver',
    },
    {
      title: 'Last connection',
      dataIndex: 'lastConnection',
      sorter: true,
      render: (date) => moment(date).format('MM/DD/Y [at] HH:mm'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      filters: _.reduce(
        STATUS_TYPES,
        (result, typeId, typeName) => {
          result.push({
            text: typeName,
            value: typeId,
          })

          return result
        },
        []
      ),
      render: (text, record) => {
        let badgeStatus = '',
          badgeLabel = ''
        switch (record.status) {
          case STATUS_TYPES.REVOKED:
            badgeStatus = 'error'
            badgeLabel = 'Revoked'
            break
          case STATUS_TYPES.NOTAPPROVED:
            badgeStatus = 'error'
            badgeLabel = 'Not approved'
            break
          case STATUS_TYPES.LIMITEXCEEDED:
            badgeStatus = 'error'
            badgeLabel = 'Limit exceeded'
            break
          case STATUS_TYPES.EXPIRED:
            badgeStatus = 'error'
            badgeLabel = 'Expired'
            break
          case STATUS_TYPES.APPROVED:
            badgeStatus = 'success'
            badgeLabel = 'Approved'
            break
          case 0:
          case STATUS_TYPES.PENDING:
          default:
            badgeStatus = 'processing'
            badgeLabel = 'Pending'
            break
        }
        return (
          <Space>
            <Badge status={badgeStatus} />
            {badgeLabel}
          </Space>
        )
      },
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      sorter: true,
      render: (date) => moment(date).format('MM/DD/Y [at] HH:mm'),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (text, record) => (
        <Space size={'small'}>
          <Popconfirm
            visible={changeApprovalStatusPopconfirmKey === record._id}
            title={'Are you sure?'}
            onConfirm={() =>
              changeApprovalStatusHandler(record._id, record.status)
            }
            okButtonProps={{
              loading: approvingLicense || revokingLicense,
              disabled: approvingLicense || revokingLicense,
            }}
            onCancel={() => setChangeApprovalStatusPopconfirmKey(null)}
          >
            <a onClick={() => setChangeApprovalStatusPopconfirmKey(record._id)}>
              {record.status === STATUS_TYPES.APPROVED ? 'Revoke' : 'Approve'}
            </a>
          </Popconfirm>
        </Space>
      ),
    },
  ]

  return (
    <TableContainer
      title={'Licenses'}
      queryStringDefaults={queryStringDefaults}
      tableProps={{
        columns,
        dataSource: licensesList.data,
        loading: licensesList.loading,
        pagination: {
          total: licensesList.total,
        },
        onChange: (pagination, filters, sorter) => {
          getQueryObject(pagination, filters, sorter)
          // dispatch(
          //   fetchLicensesList(getQueryObject(pagination, filters, sorter))
          // )
        },
      }}
    />
  )
}

export default Licenses
