import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Radio, Typography, Spin, Divider, Button, Form } from 'antd'
import {
  DropboxOutlined,
  WindowsOutlined,
  CloudServerOutlined,
} from '@ant-design/icons'

import CLOUD_PROVIDERS from '../../../constants/cloudProviders'
import ProviderSettings from './ProviderSettings'
import ResourceServer from './ResourceServer'
import {
  createCloudProvider,
  fetchCloudProvidersDetails,
  fetchCloudProvidersMe,
} from '../../../store/actions/cloudProviders.actions'
import useAuth from '../../../hooks/useAuth'

const Resources = ({ customer }) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const { isSuperUser } = useAuth()
  const [
    customersMe,
    creatingCloudProvider,
    updatingCloudProviderSettings,
    cloudProvidersDetails,
    cloudProvidersMe,
  ] = useSelector((state) => [
    state.customers.me,
    state.cloudProviders.creatingCloudProvider,
    state.cloudProviders.updatingCloudProviderSettings,
    state.cloudProviders.details,
    state.cloudProviders.me,
  ])
  const [isFormTouched, setIsFormTouched] = useState(false)
  const [selectedCloudProvider, setSelectedCloudProvider] = useState('dropbox')

  const cloudProvider = isSuperUser ? cloudProvidersDetails : cloudProvidersMe

  const customerData = customer.data
  const cloudProviderData = cloudProvider.data
  useEffect(() => {
    if (cloudProviderData) {
      setSelectedCloudProvider(cloudProviderData.provider)
    }
  }, [cloudProviderData])

  useEffect(() => {
    if (customerData) {
      if (isSuperUser) {
        dispatch(
          fetchCloudProvidersDetails({
            customerId: customerData._id,
          })
        )
      } else {
        dispatch(fetchCloudProvidersMe())
      }
    }
  }, [isSuperUser, customerData, dispatch])

  if (cloudProvider.loading || customer.loading || !customer.data) {
    return <Spin />
  }

  const saveCloudProviderHandler = (values) => {
    dispatch(
      createCloudProvider({
        ...values,
        customer: customerData._id,
        superuser: isSuperUser,
      })
    ).then((thunkResult) => {
      if (thunkResult.meta.requestStatus !== 'rejected') {
        setIsFormTouched(false)
      }
    })
  }

  let cloudProviderComponent = null
  if (selectedCloudProvider === CLOUD_PROVIDERS.dropbox.id) {
    cloudProviderComponent = (
      <ProviderSettings
        form={form}
        onFinish={saveCloudProviderHandler}
        onValuesChange={() => setIsFormTouched(true)}
        onMakeFormPure={() => setIsFormTouched(false)}
        isSuperUser={isSuperUser}
        disableSignInForSuperUser={
          isSuperUser && customersMe.data?._id !== customer.data._id
        }
        providerId={CLOUD_PROVIDERS.dropbox.id}
        providerName={CLOUD_PROVIDERS.dropbox.name}
        providerURL={CLOUD_PROVIDERS.dropbox.authorizeUrl}
        cloudProvider={cloudProviderData}
      />
    )
  } else if (selectedCloudProvider === CLOUD_PROVIDERS.onedrive.id) {
    cloudProviderComponent = (
      <ProviderSettings
        form={form}
        onFinish={saveCloudProviderHandler}
        onValuesChange={() => setIsFormTouched(true)}
        onMakeFormPure={() => setIsFormTouched(false)}
        isSuperUser={isSuperUser}
        disableSignInForSuperUser={
          isSuperUser && customersMe.data?._id !== customer.data._id
        }
        providerId={CLOUD_PROVIDERS.onedrive.id}
        providerName={CLOUD_PROVIDERS.onedrive.name}
        providerURL={CLOUD_PROVIDERS.onedrive.authorizeUrl}
        cloudProvider={cloudProviderData}
      />
    )
  } else if (selectedCloudProvider === CLOUD_PROVIDERS.resourceserver.id) {
    cloudProviderComponent = (
      <ResourceServer
        form={form}
        onFinish={saveCloudProviderHandler}
        onValuesChange={() => setIsFormTouched(true)}
        isSuperUser={isSuperUser}
        cloudProvider={cloudProviderData}
      />
    )
  }

  const selectedCloudProviderChangedHandler = (e) => {
    setSelectedCloudProvider(e.target.value)
    setIsFormTouched(true)
  }

  return (
    <>
      <Row gutter={[0, 20]}>
        <Col md={12} xs={24}>
          <Typography.Title level={5}>
            Current Cloud Provider: {cloudProviderData?.name}
          </Typography.Title>
        </Col>
      </Row>
      <Row gutter={[0, 20]}>
        <Col md={12} xs={24}>
          <Typography.Paragraph>Select a Cloud Provider:</Typography.Paragraph>
          <Radio.Group
            onChange={selectedCloudProviderChangedHandler}
            value={selectedCloudProvider}
            disabled={
              creatingCloudProvider || cloudProviderData?.settings?.fullySetup
            }
            loading={creatingCloudProvider}
          >
            <Radio.Button value={CLOUD_PROVIDERS.dropbox.id}>
              <DropboxOutlined /> DropBox
            </Radio.Button>
            <Radio.Button value={CLOUD_PROVIDERS.onedrive.id}>
              <WindowsOutlined /> OneDrive
            </Radio.Button>
            <Radio.Button value={CLOUD_PROVIDERS.resourceserver.id}>
              <CloudServerOutlined /> Resource Server
            </Radio.Button>
          </Radio.Group>
        </Col>
      </Row>
      <Row>
        <Col md={12} xs={24}>
          {cloudProviderComponent}
        </Col>
      </Row>
      <Divider />
      <Row justify={'end'}>
        <Col>
          <Button
            loading={creatingCloudProvider || updatingCloudProviderSettings}
            disabled={
              creatingCloudProvider ||
              updatingCloudProviderSettings ||
              !isFormTouched
            }
            type={'primary'}
            shape={'round'}
            size={'large'}
            onClick={() => form.submit()}
          >
            Save
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default Resources
