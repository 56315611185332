import React, { useState } from 'react'
import {
  Divider,
  Form,
  Modal,
  Steps,
  Row,
  Space,
  Button,
  Col,
  Checkbox,
} from 'antd'

import classes from './CombinedForm.less'
import DetailsForm from '../DetailsForm'
import AssetsForm from '../AssetsForm'
import AssignedToForm from '../AssignedToForm'

const CombinedForm = ({
  form,
  onFinish,
  customersList,
  projectsList,
  groupsList,
  usersList,
  projectAssetTypes,
  currentCustomer,
  currentStage,
  setCurrentStage,
  addAnother,
  setAddAnother,
  onFieldsChange,
  showSuperUserFields = false,
  forceIntoProject = null,
  creatingWorkOrder,
  updatingWorkOrder,
}) => {
  const subformOnFinishHandler = () => {
    if (currentStage < steps.length - 1) {
      return setCurrentStage((prevStage) => prevStage + 1)
    }

    onFinish(form.getFieldValue())
  }

  const steps = [
    {
      title: 'Work Order Info',
      content: (
        <DetailsForm
          form={form}
          onFinish={subformOnFinishHandler}
          onFieldsChange={onFieldsChange}
          customersList={customersList}
          projectsList={projectsList}
          defaultCustomer={currentCustomer}
          showSuperUserFields={showSuperUserFields}
          forceIntoProject={forceIntoProject}
        />
      ),
    },
    {
      title: 'Connect Assets',
      content: (
        <AssetsForm
          form={form}
          onFinish={subformOnFinishHandler}
          projectAssetTypes={projectAssetTypes}
        />
      ),
    },
    {
      title: 'Assign To',
      content: (
        <AssignedToForm
          form={form}
          onFinish={subformOnFinishHandler}
          usersList={usersList}
          groupsList={groupsList}
        />
      ),
    },
  ]

  return (
    <>
      <div className={classes.Container}>
        <div className={classes.FormContainer}>
          <Steps current={currentStage}>
            {steps.map((step) => (
              <Steps.Step key={step.title} title={step.title} />
            ))}
          </Steps>
          <Divider />
          {steps[currentStage].content}
        </div>
        <div className={classes.FooterContainer}>
          <Divider />
          <Row align={'middle'} justify={'end'}>
            {/*{setAddAnother && (*/}
            {/*  <Col>*/}
            {/*    <Checkbox*/}
            {/*      checked={addAnother}*/}
            {/*      onChange={(e) => setAddAnother(e.target.checked)}*/}
            {/*    >*/}
            {/*      Add another*/}
            {/*    </Checkbox>*/}
            {/*  </Col>*/}
            {/*)}*/}
            <Col>
              <Space>
                {currentStage > 0 && (
                  <Button
                    type={'primary'}
                    disabled={creatingWorkOrder || updatingWorkOrder}
                    onClick={() =>
                      setCurrentStage((prevStage) => prevStage - 1)
                    }
                    ghost
                  >
                    Prev
                  </Button>
                )}
                {currentStage < steps.length - 1 && (
                  <Button type={'primary'} onClick={() => form.submit()}>
                    Next
                  </Button>
                )}
                {currentStage === steps.length - 1 && (
                  <Button
                    type={'primary'}
                    loading={creatingWorkOrder || updatingWorkOrder}
                    disabled={creatingWorkOrder || updatingWorkOrder}
                    onClick={() => form.submit()}
                  >
                    Save
                  </Button>
                )}
              </Space>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default CombinedForm
