import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Button, Space, Form} from 'antd'

import {createCustomer} from '../../../store/actions/customers.actions'
import ModifyDrawer from '../../ModifyDrawer'
import CustomerForm from '../CustomerForm'

const CreateCustomer = ({visible, onDrawerClose}) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [creatingCustomer] = useSelector((state) => [
    state.customers.creatingCustomer,
  ])

  const createCustomerHandler = (values) => {
    dispatch(
      createCustomer({
        active: true,
        ...values,
      })
    ).then((thunkResult) => {
      if (thunkResult.meta.requestStatus !== 'rejected') {
        form.resetFields()
        onDrawerClose()
      }
    })
  }

  return (
    <ModifyDrawer
      title={'Create Customer'}
      visible={visible}
      onDrawerClose={onDrawerClose}
      footer={
        <Space size={'small'}>
          <Button onClick={onDrawerClose}
                  shape={'round'}>
            Cancel
          </Button>
          <Button
            type={'primary'}
            shape={'round'}
            onClick={() => form.submit()}
            loading={creatingCustomer}
            disabled={creatingCustomer}
          >
            Create
          </Button>
        </Space>
      }
    >
      <CustomerForm form={form} onFinish={createCustomerHandler}/>
    </ModifyDrawer>
  )
}

export default CreateCustomer
