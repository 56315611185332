import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Row, Col, Form, Input, Button, Space } from 'antd'

import { forgotPassword } from '../../store/actions/users.actions'
import Logo from '../../components/Logo'

const ForgotPassword = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [forgotPasswordSending] = useSelector((state) => [
    state.users.forgotPasswordSending,
  ])

  const forgotPasswordHandler = (values) => {
    dispatch(forgotPassword({ email: values.email })).then((thunkResult) => {
      if (thunkResult.meta.requestStatus !== 'rejected') {
        history.replace('/signin')
      }
    })
  }

  return (
    <>
      <Row gutter={[8, 16]} justify={'center'}>
        <Logo />
      </Row>
      <Form layout={'vertical'} size={'large'} onFinish={forgotPasswordHandler}>
        <Row gutter={[8, 16]}>
          <Col span={24}>
            <Form.Item
              name={'email'}
              label={
                'We will send an email with a reset link to the account provided'
              }
              rules={[
                {
                  required: true,
                  message: 'This field is required!',
                },
                {
                  type: 'email',
                  message: 'Please provide a valid email!',
                },
              ]}
            >
              <Input placeholder={'Please provide your email address'} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 16]} justify={'end'}>
          <Col>
            <Space>
              <Button shape={'round'}>
                <Link to={'signin'}>Cancel</Link>
              </Button>
              <Button
                type={'primary'}
                shape={'round'}
                disabled={forgotPasswordSending}
                loading={forgotPasswordSending}
                htmlType={'submit'}
              >
                Send reset link
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default ForgotPassword
