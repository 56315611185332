import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Space } from 'antd'
import { PlusOutlined, MedicineBoxOutlined } from '@ant-design/icons'

import {
  fetchAssetTypesList,
  deleteAssetType,
} from '../../store/actions/assetTypes.actions'

import useAuth from '../../hooks/useAuth'
import WhatIsAssetType from '../../components/AssetType/WhatIsAssetType'
import CreateAssetType from '../../components/AssetType/CreateAssetType'
import EditAssetType from '../../components/AssetType/EditAssetType'
import TableContainer from '../../components/TableContainer'
import useTableActions from '../../hooks/useTableActions'
import CustomerTag from '../../components/Customer/CustomerTag'

const AssetTypes = () => {
  const dispatch = useDispatch()
  const { isSuperUser } = useAuth()
  const [whatIsAssetType, setWhatIsAssetType] = useState(false)
  const [createAssetType, setCreateAssetType] = useState(false)
  const [editAssetType, setEditAssetType] = useState(null)
  const {
    getColumnSearchProps,
    getEditDeleteActions,
    getAlphabeticalSorterProps,
  } = useTableActions()
  const [assetTypesList, deletingAssetType] = useSelector((state) => [
    state.assetTypes.list,
    state.assetTypes.deletingAssetType,
  ])

  useEffect(() => {
    dispatch(fetchAssetTypesList())
  }, [dispatch])

  const deleteAssetTypeHandler = (id) => {
    dispatch(deleteAssetType(id))
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      ...getColumnSearchProps('name'),
      ...getAlphabeticalSorterProps(),
    },
    {
      title: 'Customer',
      dataIndex: ['customer', 'name'],
      className: isSuperUser ? null : 'fb-column-hidden',
      render: (customer) => <CustomerTag name={customer} />,
      ...getAlphabeticalSorterProps(),
    },
    {
      title: 'Description',
      dataIndex: 'description',
    },
    {
      title: 'Table name',
      dataIndex: 'tableName',
      ...getColumnSearchProps('tableName'),
      ...getAlphabeticalSorterProps(),
    },
    {
      title: 'ID Column name',
      dataIndex: 'idColumnName',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      ...getEditDeleteActions(
        setEditAssetType,
        deleteAssetTypeHandler,
        deletingAssetType
      ),
    },
  ]

  return (
    <>
      <WhatIsAssetType
        visible={whatIsAssetType}
        onModalClose={() => setWhatIsAssetType(false)}
      />
      <CreateAssetType
        visible={createAssetType}
        onDrawerClose={() => setCreateAssetType(false)}
      />
      <EditAssetType
        visible={editAssetType}
        onDrawerClose={() => setEditAssetType(null)}
      />
      <TableContainer
        title={'Asset Types'}
        topBarActions={[
          <Button
            key={'whatIsAssetType'}
            icon={<MedicineBoxOutlined />}
            size={'large'}
            shape={'round'}
            onClick={() => setWhatIsAssetType(true)}
          >
            Help
          </Button>,
          <Button
            key={'add'}
            icon={<PlusOutlined />}
            type={'primary'}
            shape={'round'}
            size={'large'}
            onClick={() => setCreateAssetType(true)}
          >
            Add Asset Type
          </Button>,
        ]}
        tableProps={{
          columns,
          dataSource: assetTypesList.data,
          loading: assetTypesList.loading,
        }}
      />
    </>
  )
}

export default AssetTypes
