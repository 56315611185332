import React, {useEffect, useState} from 'react'
import _ from 'lodash'
import {useDispatch, useSelector} from 'react-redux'
import {Button, Space, Form} from 'antd'

import useAuth from '../../../hooks/useAuth'
import {createUser} from '../../../store/actions/users.actions'
import {fetchRolesList} from '../../../store/actions/roles.actions'
import {fetchGroupsList} from '../../../store/actions/groups.actions'
import {fetchCustomersList} from '../../../store/actions/customers.actions'

import ModifyDrawer from '../../ModifyDrawer'
import UserForm from '../UserForm'

const CreateUser = ({visible, onDrawerClose, defaultCustomer = null}) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const {isSuperUser} = useAuth()
  const [
    currentUserData,
    creatingUser,
    rolesList,
    groupsList,
    customersList,
  ] = useSelector((state) => [
    state.users.me.data,
    state.users.creatingUser,
    state.roles.list,
    state.groups.list,
    state.customers.list,
  ])
  const [currentCustomer, setCurrentCustomer] = useState(
    currentUserData.customer._id
  )

  useEffect(() => {
    if (visible && isSuperUser) {
      dispatch(fetchCustomersList())
    }
  }, [visible, dispatch, isSuperUser])

  useEffect(() => {
    // Get roles and groups!
    if (visible) {
      dispatch(fetchRolesList({customer: currentCustomer}))
      dispatch(fetchGroupsList({customer: currentCustomer}))
    }
  }, [dispatch, visible, currentCustomer])

  const customersListData = customersList.data
  // Auto select the default customer if the user is a super user
  useEffect(() => {
    if (isSuperUser && defaultCustomer && customersListData) {
      form.setFieldsValue({
        customer: defaultCustomer,
      })
    }
  }, [isSuperUser, defaultCustomer, customersListData, form])

  // Auto select the ALL_USERS group for the new user!
  const groupsListData = groupsList.data
  useEffect(() => {
    if (groupsListData) {
      const allUsersGroup = _.find(groupsListData, ['name', 'ALL_USERS'])

      if (allUsersGroup) {
        form.setFieldsValue({
          groups: [allUsersGroup._id],
        })
      }
    }
  }, [form, groupsListData])

  // Auto select the FieldWorker role for the new user!
  const rolesListData = rolesList.data
  useEffect(() => {
    if (rolesListData) {
      const fieldWorkerRole = _.find(rolesListData, ['name', 'FieldWorker'])

      if (fieldWorkerRole) {
        form.setFieldsValue({
          roles: [fieldWorkerRole._id],
        })
      }
    }
  }, [form, rolesListData])

  const formFieldsChangeHandler = (changedFields) => {
    const customerFields = changedFields.filter(
      (changedField) => changedField.name[0] === 'customer'
    )

    if (customerFields.length > 0) {
      setCurrentCustomer(customerFields[0].value)
      form.resetFields(['roles', 'groups'])
    }
  }

  const createUserHandler = (values) => {
    dispatch(
      createUser({
        customer: currentUserData.customer._id,
        ...values,
      })
    ).then((thunkResult) => {
      if (thunkResult.meta.requestStatus !== 'rejected') {
        form.resetFields()
        onDrawerClose()
      }
    })
  }

  return (
    <ModifyDrawer
      title={'Create user'}
      footer={
        <Space size={'small'}>
          <Button onClick={onDrawerClose}
                  shape={'round'}>Cancel</Button>
          <Button
            type={'primary'}
            shape={'round'}
            onClick={() => form.submit()}
            loading={creatingUser}
            disabled={creatingUser}
          >
            Create
          </Button>
        </Space>
      }
      onDrawerClose={onDrawerClose}
      visible={visible}
    >
      <UserForm
        form={form}
        onFinish={createUserHandler}
        onFieldsChange={formFieldsChangeHandler}
        rolesList={rolesList}
        groupsList={groupsList}
        customersList={customersList}
        defaultCustomer={currentCustomer}
        showSuperUserFields={isSuperUser}
      />
    </ModifyDrawer>
  )
}

export default CreateUser
