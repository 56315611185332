import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Space, Button, Spin } from 'antd'

import {
  fetchGroupsDetails,
  updateGroup,
} from '../../../store/actions/groups.actions'
import { fetchUsersList } from '../../../store/actions/users.actions'
import ModifyDrawer from '../../ModifyDrawer'
import GroupForm from '../GroupForm'

const EditGroup = ({ visible, onDrawerClose }) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const [groupsDetails, updatingGroup, usersList] = useSelector((state) => [
    state.groups.details,
    state.groups.updatingGroup,
    state.users.list,
  ])

  useEffect(() => {
    if (visible) {
      dispatch(fetchGroupsDetails(visible))
    }
  }, [dispatch, visible])

  const groupCustomerId = groupsDetails.data?.customer._id
  useEffect(() => {
    if (visible && groupCustomerId) {
      dispatch(fetchUsersList({ customer: groupCustomerId }))
    }
  }, [dispatch, visible, groupCustomerId])

  const groupsDetailsData = groupsDetails.data
  useEffect(() => {
    if (groupsDetailsData) {
      form.setFieldsValue({
        name: groupsDetailsData.name,
        users: groupsDetailsData.users,
      })
    }
  }, [groupsDetailsData, form])

  const updateGroupHandler = (values) => {
    dispatch(
      updateGroup({
        id: visible,
        data: {
          ...groupsDetails.data,
          ...values,
        },
      })
    ).then(() => {
      onDrawerClose()
    })
  }

  return (
    <ModifyDrawer
      title={'Update group'}
      visible={visible}
      onDrawerClose={onDrawerClose}
      footer={
        <Space size={'small'}>
          <Button onClick={onDrawerClose}>Cancel</Button>
          <Button
            type={'primary'}
            onClick={() => form.submit()}
            loading={updatingGroup}
            disabled={updatingGroup}
          >
            Save
          </Button>
        </Space>
      }
    >
      {groupsDetails.loading ? (
        <Spin />
      ) : (
        <GroupForm
          form={form}
          onFinish={updateGroupHandler}
          usersList={usersList}
        />
      )}
    </ModifyDrawer>
  )
}

export default EditGroup
