import React from 'react'

import UserTag from '../../User/UserTag'
import GroupTag from '../../Group/GroupTag'

const AssignedToTags = ({ users = [], groups = [] }) => {
  const tagsArray = [
    ...users.map((user) => <UserTag name={user.name} key={user._id} />),
    ...groups.map((group) => <GroupTag name={group.name} key={group._id} />),
  ]

  return tagsArray
}

export default AssignedToTags
