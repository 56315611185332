import React from 'react'
import { SiDropbox, SiMicrosoftonedrive } from 'react-icons/si'
import { CloudServerOutlined } from '@ant-design/icons'

const CLOUD_PROVIDERS = {
  dropbox: {
    id: 'dropbox',
    name: 'DropBox',
    icon: <SiDropbox />,
    authorizeUrl: `https://www.dropbox.com/oauth2/authorize?client_id=${process.env.REACT_APP_DROPBOX_APP_KEY}&response_type=code&redirect_uri=${process.env.REACT_APP_DROPBOX_REDIRECT_URI}&token_access_type=offline&force_reauthentication=true`,
  },
  onedrive: {
    id: 'onedrive',
    name: 'OneDrive',
    icon: <SiMicrosoftonedrive />,
    authorizeUrl: `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${process.env.REACT_APP_ONEDRIVE_APP_KEY}&scope=files.readwrite%20offline_access&response_type=code&redirect_uri=${process.env.REACT_APP_ONEDRIVE_REDIRECT_URI}`,
  },
  resourceserver: {
    id: 'resourceserver',
    name: 'Resource Server',
    icon: <CloudServerOutlined />,
    authorizeUrl: '',
  },
}

export default CLOUD_PROVIDERS
