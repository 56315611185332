import React, { useState } from 'react'
import _ from 'lodash'
import Papa from 'papaparse'
import { FileOutlined } from '@ant-design/icons'
import {
  Col,
  Form,
  Row,
  Select,
  Input,
  Button,
  Upload,
  Space,
  Alert,
  message,
  notification,
} from 'antd'

import AssetList from '../AssetList'

const AssetsForm = ({ form: parentForm, projectAssetTypes }) => {
  const [parsingCSV, setParsingCSV] = useState(false)
  const [assetsAdded, setAssetsAdded] = useState(
    parentForm.getFieldValue('assetsTargeted') || []
  )
  const [form] = Form.useForm()

  const onChildFormFinish = (values) => {
    assetAddHandler(values)

    form.resetFields()
  }

  const assetAddHandler = (values) => {
    setAssetsAdded((currentState) => {
      const newState = [
        ...currentState,
        {
          ...values,
        },
      ]

      parentForm.setFieldsValue({
        assetsTargeted: newState,
      })

      return newState
    })
  }

  const assetRemoveHandler = (id) => {
    setAssetsAdded((currentState) => {
      const indexToDelete = _.findIndex(currentState, ['foreignId', id])

      const stateCopy = [...currentState]
      stateCopy.splice(indexToDelete, 1)

      parentForm.setFieldsValue({
        assetsTargeted: stateCopy,
      })

      return stateCopy
    })
  }

  const importFromCSVHandler = (file) => {
    setParsingCSV(true)

    Papa.parse(file, {
      complete: (results) => {
        let successCount = 0
        let errorCount = 0
        _.forEach(results.data, (row) => {
          // Each row has to have 2 items
          if (_.size(row) !== 2) {
            errorCount++
            return
          }

          assetAddHandler({
            assetType: row[0],
            foreignId: row[1],
          })
          successCount++
        })

        notification.info({
          message: 'Assets Imported',
          placement: 'bottomRight',
          description: (
            <Space direction={'vertical'}>
              <Alert
                type={'success'}
                message={`Imported ${successCount} Assets.`}
              />
              <Alert
                type={'error'}
                message={`Failed to import ${errorCount} Assets.`}
              />
            </Space>
          ),
          duration: 8,
        })
      },
      error: () => {
        message.error('Something went wrong while parsing the CSV file')
        setParsingCSV(false)
      },
    })

    return false
  }

  return (
    <Form layout={'vertical'} form={form} onFinish={onChildFormFinish}>
      <Row justify={'end'}>
        <Col>
          <Upload
            name={'importFromCSV'}
            accept={'text/csv'}
            showUploadList={false}
            multiple={false}
            beforeUpload={importFromCSVHandler}
          >
            <Button icon={<FileOutlined />}>Import from CSV</Button>
          </Upload>
        </Col>
      </Row>
      <Row gutter={16} align={'middle'}>
        <Col flex={'auto'}>
          <Form.Item
            name={'assetType'}
            label={'Asset Type'}
            rules={[{ required: true, message: 'This field is required!' }]}
          >
            <Select
              options={projectAssetTypes.map((assetType) => ({
                label: assetType.name,
                value: assetType._id,
              }))}
            />
          </Form.Item>
        </Col>
        <Col flex={'auto'}>
          <Form.Item
            name={'foreignId'}
            label={'Asset ID'}
            rules={[{ required: true, message: 'This field is required!' }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col flex={'none'}>
          <Button htmlType={'submit'} type={'primary'}>
            Add
          </Button>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <AssetList
            dataSource={assetsAdded}
            projectAssetTypes={projectAssetTypes}
            onAssetRemove={assetRemoveHandler}
          />
        </Col>
      </Row>
    </Form>
  )
}

export default AssetsForm
