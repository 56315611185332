import React from 'react'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'

import { Drawer, Menu, Grid } from 'antd'
import {
  UserOutlined,
  LogoutOutlined,
  DashboardOutlined,
  BankOutlined,
  SolutionOutlined,
  ContainerOutlined,
  TabletOutlined,
  GlobalOutlined,
  FolderOutlined,
  SettingOutlined,
  FileOutlined,
  TeamOutlined,
} from '@ant-design/icons'

import * as APP_TYPES from '../../constants/applicationTypes'
import useAuth from '../../hooks/useAuth'
import classes from './SideBar.less'
import { signOut } from '../../store/actions/auth.actions'
import ApplicationPicker from '../ApplicationPicker'

const SideBar = ({ visible, closeSideBar }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { pathname } = useLocation()
  const screens = Grid.useBreakpoint()
  const { isSuperUser, hasAction, hasAnyAction, allActions } = useAuth()
  const [activeApplication] = useSelector((state) => [state.application.active])

  const goToLink = (link) => {
    if (pathname !== link) {
      history.push(link)
    }
  }

  const logOutHandler = () => {
    dispatch(signOut())
  }

  let mainMenuItems = null
  switch (activeApplication) {
    case APP_TYPES.RESOURCES:
      mainMenuItems = (
        <>
          <Menu.Item
            icon={<GlobalOutlined />}
            onClick={() => goToLink('/resources')}
          >
            Viewer
          </Menu.Item>
        </>
      )
      break
    case APP_TYPES.WORK_ORDER_MANAGEMENT:
      mainMenuItems = (
        <>
          <Menu.Item icon={<DashboardOutlined />} onClick={() => goToLink('/')}>
            Dashboard
          </Menu.Item>
          {hasAction(allActions.adminactions) && (
            <Menu.Item
              icon={<FolderOutlined />}
              onClick={() => goToLink('/projects')}
            >
              Projects
            </Menu.Item>
          )}
          <Menu.Item
            icon={<FileOutlined />}
            onClick={() => goToLink('/workOrders')}
          >
            Work Orders
          </Menu.Item>
          <Menu.Item
            icon={<GlobalOutlined />}
            onClick={() => goToLink('/assetTypes')}
          >
            Asset Types
          </Menu.Item>
        </>
      )
      break
    case APP_TYPES.ORGANIZATION_MANAGEMENT:
    default:
      mainMenuItems = (
        <>
          {hasAnyAction([allActions.viewhome, allActions.adminactions]) && (
            <Menu.Item
              icon={<DashboardOutlined />}
              onClick={() => goToLink('/')}
            >
              Dashboard
            </Menu.Item>
          )}
          {isSuperUser && (
            <Menu.Item
              icon={<BankOutlined />}
              onClick={() => goToLink('/customers')}
            >
              Customers
            </Menu.Item>
          )}
          {hasAction(allActions.adminactions) && (
            <>
              <Menu.Item
                icon={<UserOutlined />}
                onClick={() => goToLink('/users')}
              >
                Users
              </Menu.Item>
              {/*<Menu.Item icon={<TeamOutlined />}>*/}
              {/*  <Link to={'/groups'}>Groups</Link>*/}
              {/*</Menu.Item>*/}
              {/*<Menu.Item icon={<SolutionOutlined />}>*/}
              {/*  <Link to={'/roles'}>Roles</Link>*/}
              {/*</Menu.Item>*/}
              <Menu.Item
                icon={<ContainerOutlined />}
                onClick={() => goToLink('/licenses')}
              >
                Licenses
              </Menu.Item>
              <Menu.Item
                icon={<TabletOutlined />}
                onClick={() => goToLink('/devices')}
              >
                Devices
              </Menu.Item>
            </>
          )}
        </>
      )
      break
  }

  const goToSettingsHandler = () => {
    if (activeApplication === APP_TYPES.WORK_ORDER_MANAGEMENT) {
      goToLink(`/settings/wom/me`)
    } else if (activeApplication === APP_TYPES.RESOURCES) {
      goToLink(`/settings/resource/me`)
    } else {
      goToLink(`/settings/general/me`)
    }
  }

  return (
    <Drawer
      placement={'right'}
      width={300}
      visible={visible}
      onClose={closeSideBar}
      closable
    >
      <div className={classes.Container}>
        {!screens.md && <ApplicationPicker isMobile={!screens.md} />}
        <Menu className={clsx(classes.Menu, classes.FullSize)}>
          {mainMenuItems}
        </Menu>
        <Menu className={classes.Menu}>
          {hasAnyAction([
            allActions.managecustomersettings,
            allActions.adminactions,
          ]) && (
            <Menu.Item onClick={goToSettingsHandler} icon={<SettingOutlined />}>
              Settings
            </Menu.Item>
          )}
          <Menu.Item icon={<UserOutlined />}>
            <Link to={'/profile'}>Profile</Link>
          </Menu.Item>
          <Menu.Item icon={<LogoutOutlined />} onClick={logOutHandler}>
            Log out
          </Menu.Item>
        </Menu>
      </div>
    </Drawer>
  )
}

export default SideBar
