import React from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Button, Layout, Row, Col, Typography, Space, Grid } from 'antd'
import { MenuOutlined } from '@ant-design/icons'

import classes from './Header.less'
import ApplicationPicker from '../ApplicationPicker'

const Header = ({ openSideMenu }) => {
  const [userEmail] = useSelector((state) => [state.users.me.data?.email])
  const screens = Grid.useBreakpoint()

  const isXs = screens.xs && !screens.sm

  return (
    <Layout.Header
      className={clsx(classes.Header, isXs && classes.HeaderMobile)}
    >
      <Row
        align={'middle'}
        wrap={false}
        gutter={10}
        className={classes.Container}
      >
        <Col flex={!screens.md ? 'auto' : 'none'}>
          <Typography.Title level={2} className={classes.Title}>
            FieldBox
          </Typography.Title>
        </Col>
        {screens.md && (
          <Col flex={'auto'}>
            <ApplicationPicker />
          </Col>
        )}
        <Col flex={'none'}>
          <Space size={'small'}>
            {!isXs && (
              <Button type={'primary'} size={'large'}>
                <Link to={'/profile'}>{userEmail}</Link>
              </Button>
            )}
            <Button type={'primary'} size={'large'} onClick={openSideMenu}>
              <MenuOutlined />
            </Button>
          </Space>
        </Col>
      </Row>
    </Layout.Header>
  )
}

export default Header
