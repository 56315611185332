import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import { Space, Popconfirm } from 'antd'

import {
  fetchDevicesList,
  deleteDevice,
} from '../../store/actions/devices.actions'
import useAuth from '../../hooks/useAuth'
import useTable from '../../hooks/useTable'
import FilterDropdown from '../../components/FilterDropdown'
import TableContainer from '../../components/TableContainer'
import CustomerTag from '../../components/Customer/CustomerTag'

const Devices = () => {
  const dispatch = useDispatch()
  const { isSuperUser } = useAuth()
  const { queryStringDefaults, getQueryObject } = useTable()
  const [deleteDevicePopconfirmKey, setDeleteDevicePopconfirmKey] = useState(
    null
  )
  const [devicesList, deletingDevice] = useSelector((state) => [
    state.devices.list,
    state.devices.deletingDevice,
  ])

  const deleteDeviceHandler = (id) => {
    dispatch(deleteDevice(id))
  }

  useEffect(() => {
    dispatch(fetchDevicesList(queryStringDefaults))
  }, [dispatch, queryStringDefaults])

  const columns = [
    {
      title: 'Device name',
      dataIndex: 'name',
      filterDropdown: (props) => <FilterDropdown {...props} />,
      defaultFilteredValue: queryStringDefaults.name || [],
      sorter: true,
    },
    {
      title: 'Device ID',
      dataIndex: 'uuid',
    },
    {
      title: 'Customer',
      dataIndex: 'customer',
      className: isSuperUser ? null : 'fb-column-hidden',
      render: (customer) => <CustomerTag name={customer.name} />,
    },
    {
      title: 'Device info',
      dataIndex: 'state',
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      sorter: true,
      render: (date) => moment(date).format('MM/DD/Y [at] HH:mm'),
    },
    {
      title: 'Updated at',
      dataIndex: 'updatedAt',
      sorter: true,
      render: (date) => moment(date).format('MM/DD/Y [at] HH:mm'),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (text, record) => (
        <Space size={'small'}>
          <Popconfirm
            visible={deleteDevicePopconfirmKey === record._id}
            title={`Are you sure?`}
            onConfirm={() => deleteDeviceHandler(record._id)}
            okButtonProps={{
              loading: deletingDevice,
              disabled: deletingDevice,
            }}
            onCancel={() => setDeleteDevicePopconfirmKey(null)}
          >
            <a onClick={() => setDeleteDevicePopconfirmKey(record._id)}>
              Delete
            </a>
          </Popconfirm>
        </Space>
      ),
    },
  ]

  return (
    <TableContainer
      title={'Devices'}
      queryStringDefaults={queryStringDefaults}
      tableProps={{
        columns,
        dataSource: devicesList.data,
        loading: devicesList.loading,
        pagination: {
          total: devicesList.total,
        },
        onChange: (pagination, filters, sorter) => {
          getQueryObject(pagination, filters, sorter)
          // dispatch(
          //   fetchDevicesList(getQueryObject(pagination, filters, sorter))
          // )
        },
      }}
    />
  )
}

export default Devices
