import React from 'react'
import { Form, Row, Col, Input, Select } from 'antd'

import GroupTag from '../../Group/GroupTag'

const FolderForm = ({ form, onFinish, groupsList }) => {
  return (
    <Form layout={'vertical'} form={form} onFinish={onFinish}>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name={'folderName'}
            label={'Folder Name'}
            rules={[{ required: true, message: 'This field is required!' }]}
          >
            <Input placeholder={'Please provide a name for the folder'} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name={'permittedGroups'}
            label={'Groups with access to the folder'}
          >
            <Select
              loading={groupsList.loading}
              disabled={groupsList.loading}
              options={groupsList.data.map((group) => ({
                label: group.name,
                value: group._id,
              }))}
              tagRender={({ label, value, closable, onClose }) => (
                <GroupTag name={label} closable={closable} onClose={onClose} />
              )}
              mode={'multiple'}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

export default FolderForm
