import React, { useState } from 'react'
import { Layout } from 'antd'

import classes from './FullPageLayout.less'
import Header from '../Header'
import SideBar from '../SideBar'

const FullPageLayout = ({ children }) => {
  const [showingSideBar, setShowingSideBar] = useState(false)
  return (
    <>
      <SideBar
        visible={showingSideBar}
        closeSideBar={() => setShowingSideBar(false)}
      />
      <Layout className={classes.Container}>
        <Header openSideMenu={() => setShowingSideBar(true)} />
        <Layout.Content className={classes.ContentContainer}>
          {children}
        </Layout.Content>
      </Layout>
    </>
  )
}

export default FullPageLayout
