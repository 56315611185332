import React from 'react'
import { Tag, Tooltip } from 'antd'
import { DatabaseOutlined } from '@ant-design/icons'

const ResourceServerUserTag = ({ ...props }) => (
  <Tooltip
    title={
      'Resource Server User - used only to authenticate with the Resource Server'
    }
  >
    <Tag color={'gold'} icon={<DatabaseOutlined />} {...props}>
      RS User
    </Tag>
  </Tooltip>
)

export default ResourceServerUserTag
