import axiosBackendInstance from './backendInstance'

export const signIn = async (email, password) => {
  const response = await axiosBackendInstance.post('/auth/local', {
    email,
    password,
  })

  return response.data
}

export const refreshToken = async (token) => {
  const response = await axiosBackendInstance.post(
    '/auth/local/refresh',
    {
      token,
    },
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  )

  return response.data
}
