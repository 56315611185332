import React, { useEffect, useState } from 'react'
import qs from 'qs'
import _ from 'lodash'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Space, Progress, Divider } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import {
  fetchCustomersList,
  deleteCustomer as deleteCustomerAction,
} from '../../store/actions/customers.actions'
import ConfirmDeleteModal from '../../components/ConfirmDeleteModal'
import CreateCustomer from '../../components/Customer/CreateCustomer'
import TableContainer from '../../components/TableContainer'
import FilterDropdown from '../../components/FilterDropdown'
import useTable from '../../hooks/useTable'

const Customers = () => {
  const dispatch = useDispatch()
  const { queryStringDefaults, getQueryObject } = useTable()
  const [createCustomer, setCreateCustomer] = useState(false)
  const [deleteCustomer, setDeleteCustomer] = useState({
    id: undefined,
    name: undefined,
  })
  const [customersList, deletingCustomer] = useSelector((state) => [
    state.customers.list,
    state.customers.deletingCustomer,
  ])

  const deleteCustomerHandler = (id) => {
    dispatch(deleteCustomerAction(id)).then(() =>
      setDeleteCustomer({ id: undefined, name: undefined })
    )
  }

  useEffect(() => {
    if (!createCustomer) {
      dispatch(fetchCustomersList(queryStringDefaults))
    }
  }, [dispatch, queryStringDefaults, createCustomer])

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      filterDropdown: (props) => <FilterDropdown {...props} />,
      defaultFilteredValue: queryStringDefaults.name || [],
      sorter: true,
    },
    {
      title: 'Contact email',
      dataIndex: 'contactEmail',
      filterDropdown: (props) => <FilterDropdown {...props} />,
      defaultFilteredValue: queryStringDefaults.contactEmail || [],
      sorter: true,
    },
    {
      title: 'Licenses',
      dataIndex: 'licenses',
      render: (licenses, record) => {
        return (
          <Progress
            steps={10}
            percent={_.round(
              (record.licensesUsed / record.maxLicenseNo) * 100,
              0
            )}
          />
        )
      },
    },
    {
      title: 'Valid Until',
      dataIndex: 'validUntil',
      render: (date) => {
        if (!date) return 'Indefinitely'

        return moment(date).format('MM/DD/Y')
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (actions, record) => (
        <Space split={<Divider type="vertical" />}>
          <a>
            <Link to={`/users?${qs.stringify({ customer: [record._id] })}`}>
              Show Users
            </Link>
          </a>
          <a>
            <Link to={`/settings/superUser/${record._id}`}>Settings</Link>
          </a>
          <a
            onClick={() =>
              setDeleteCustomer({ id: record._id, name: record.name })
            }
          >
            Delete
          </a>
        </Space>
      ),
    },
  ]

  return (
    <>
      <ConfirmDeleteModal
        visible={deleteCustomer.id}
        onConfirmDelete={deleteCustomerHandler}
        onModalClose={() =>
          setDeleteCustomer({ id: undefined, name: undefined })
        }
        deleting={deletingCustomer}
        magicPhrase={deleteCustomer.name}
      />
      <CreateCustomer
        visible={createCustomer}
        onDrawerClose={() => setCreateCustomer(false)}
      />
      <TableContainer
        title={'Customers'}
        topBarActions={[
          <Button
            key={'0'}
            icon={<PlusOutlined />}
            type={'primary'}
            shape={'round'}
            size={'large'}
            onClick={() => setCreateCustomer(true)}
          >
            Add Customer
          </Button>,
        ]}
        queryStringDefaults={queryStringDefaults}
        tableProps={{
          columns,
          dataSource: customersList.data,
          loading: customersList.loading,
          pagination: {
            total: customersList.total,
          },
          onChange: (pagination, filters, sorter) => {
            getQueryObject(pagination, filters, sorter)
            // dispatch(
            //   fetchCustomersList(getQueryObject(pagination, filters, sorter))
            // )
          },
        }}
      />
    </>
  )
}

export default Customers
