import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Space,
  Form,
  Row,
  Col,
  Input,
  InputNumber,
  Divider,
} from 'antd'

import { markCompleted } from '../../../store/actions/workOrders.actions'

import ModifyDrawer from '../../ModifyDrawer'

const MarkComplete = ({ visible, onDrawerClose }) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [markingCompleted] = useSelector((state) => [
    state.workOrders.markingCompleted,
  ])

  const markAsCompleteHandler = (values) => {
    const timeSpent = values.timeSpent?.hours * 60 + values.timeSpent?.minutes

    dispatch(
      markCompleted({
        id: visible,
        data: {
          completionNotes: values.completionNotes,
          timeSpent,
        },
      })
    ).then(() => {
      form.resetFields()
      onDrawerClose()
    })
  }

  return (
    <ModifyDrawer
      title={'Complete Work Order'}
      visible={visible}
      onDrawerClose={onDrawerClose}
      footer={
        <Space size={'small'}>
          <Button onClick={onDrawerClose}>Cancel</Button>
          <Button
            type={'primary'}
            onClick={() => form.submit()}
            loading={markingCompleted}
            disabled={markingCompleted}
          >
            Mark as Completed
          </Button>
        </Space>
      }
    >
      <Form
        layout={'vertical'}
        form={form}
        onFinish={markAsCompleteHandler}
        initialValues={{
          timeSpent: {
            hours: 0,
            minutes: 0,
          },
        }}
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name={'completionNotes'} label={'Completion Notes'}>
              <Input.TextArea
                maxLength={500}
                autoSize={{ minRows: 4, maxRows: 6 }}
                showCount
              />
            </Form.Item>
          </Col>
        </Row>
        <Divider orientation={'left'}>Time Spent</Divider>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name={['timeSpent', 'hours']}
              label={'Hours'}
              rules={[{ required: true, message: 'This field is required!' }]}
            >
              <InputNumber min={0} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name={['timeSpent', 'minutes']}
              label={'Minutes'}
              rules={[{ required: true, message: 'This field is required!' }]}
            >
              <InputNumber min={0} max={59} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </ModifyDrawer>
  )
}

export default MarkComplete
