import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Row, Col, PageHeader, Descriptions, Card, Button, Space } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'

import RoleTag from '../../components/Role/RoleTag'
import ChangePassword from '../../components/User/ChangePassword'

const Profile = () => {
  const history = useHistory()
  const [changePasswordVisible, setChangePasswordVisible] = useState(false)
  const [userData] = useSelector((state) => [state.users.me.data])

  return (
    <>
      <ChangePassword
        visible={changePasswordVisible}
        onDrawerClose={() => setChangePasswordVisible(false)}
      />
      <Row gutter={[8, 16]}>
        <Col span={24}>
          <PageHeader
            title={'User profile'}
            ghost={false}
            extra={[
              <Button
                key={'0'}
                type={'primary'}
                shape={'round'}
                onClick={() => setChangePasswordVisible(true)}
              >
                Change Password
              </Button>,
            ]}
          />
        </Col>
      </Row>
      <Row gutter={[8, 16]}>
        <Col span={24}>
          <Card>
            <Descriptions size={'large'} column={1} bordered>
              <Descriptions.Item label={'Name'}>
                {userData.name}
              </Descriptions.Item>
              <Descriptions.Item label={'Email'}>
                {userData.email}
              </Descriptions.Item>
              <Descriptions.Item label={'Customer'}>
                {userData.customer.name}
              </Descriptions.Item>
              <Descriptions.Item label={'Roles'}>
                {userData.roles.map((role) => (
                  <RoleTag key={role._id} name={role.name} />
                ))}
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default Profile
