import { createReducer } from '@reduxjs/toolkit'
import _ from 'lodash'

import {
  fetchGroupsList,
  fetchGroupsDetails,
  createGroup,
  updateGroup,
  deleteGroup,
  bulkDeleteGroups,
} from '../actions/groups.actions'

const initialState = {
  list: {
    loading: false,
    data: [],
  },
  details: {
    loading: false,
    data: null,
  },
  creatingGroup: false,
  updatingGroup: false,
  deletingGroup: false,
  bulkDeletingGroups: false,
}

const groupsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchGroupsList.pending, (state, action) => {
      state.list.loading = true
      state.list.data = []
    })
    .addCase(fetchGroupsList.fulfilled, (state, action) => {
      state.list.loading = false
      state.list.data = action.payload
    })
    .addCase(fetchGroupsDetails.pending, (state, action) => {
      state.details.loading = true
      state.details.data = null
    })
    .addCase(fetchGroupsDetails.fulfilled, (state, action) => {
      state.details.loading = false
      state.details.data = action.payload
    })
    .addCase(createGroup.pending, (state, action) => {
      state.creatingGroup = true
    })
    .addCase(createGroup.fulfilled, (state, action) => {
      state.list.data.push(action.payload)

      state.creatingGroup = false
    })
    .addCase(createGroup.rejected, (state, action) => {
      state.creatingGroup = false
    })
    .addCase(updateGroup.pending, (state, action) => {
      state.updatingGroup = true
    })
    .addCase(updateGroup.fulfilled, (state, action) => {
      const updatedIndex = _.findIndex(state.list.data, [
        '_id',
        action.payload._id,
      ])

      // Update the list
      state.list.data[updatedIndex] = action.payload
      // Update the selected item
      state.details.data = action.payload

      state.updatingGroup = false
    })
    .addCase(updateGroup.rejected, (state, action) => {
      state.updatingGroup = false
    })
    .addCase(deleteGroup.pending, (state, action) => {
      state.deletingGroup = true
    })
    .addCase(deleteGroup.fulfilled, (state, action) => {
      const deletedIndex = _.findIndex(state.list.data, ['_id', action.payload])

      // Delete from the list
      state.list.data.splice(deletedIndex, 1)

      // Delete if selected
      if (state.details.data?._id === action.payload) {
        state.details.data = null
      }

      state.deletingGroup = false
    })
    .addCase(deleteGroup.rejected, (state, action) => {
      state.deletingGroup = false
    })
    .addCase(bulkDeleteGroups.pending, (state, action) => {
      state.bulkDeletingGroups = true
    })
    .addCase(bulkDeleteGroups.fulfilled, (state, action) => {
      // Remove from the list
      state.list.data = state.list.data.filter((item) => {
        return _.findIndex(action.payload, (a) => a === item._id) === -1
      })

      // Remove if selected
      if (
        _.findIndex(action.payload, (a) => a === state.details.data?._id) > -1
      ) {
        state.details.data = null
      }

      state.bulkDeletingGroups = false
    })
    .addCase(bulkDeleteGroups.rejected, (state, action) => {
      state.bulkDeletingGroups = false
    })
})

export default groupsReducer
