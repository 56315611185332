import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Space, Form } from 'antd'

import useAuth from '../../../hooks/useAuth'
import { createGroup } from '../../../store/actions/groups.actions'
import { fetchUsersList } from '../../../store/actions/users.actions'
import { fetchCustomersList } from '../../../store/actions/customers.actions'
import ModifyDrawer from '../../ModifyDrawer'
import GroupForm from '../GroupForm'

const CreateGroup = ({ visible, onDrawerClose }) => {
  const dispatch = useDispatch()
  const { isSuperUser } = useAuth()
  const [form] = Form.useForm()
  const [
    currentUserData,
    usersList,
    customersList,
    creatingGroup,
  ] = useSelector((state) => [
    state.users.me.data,
    state.users.list,
    state.customers.list,
    state.groups.creatingGroup,
  ])
  const [currentCustomer, setCurrentCustomer] = useState(
    currentUserData.customer._id
  )

  useEffect(() => {
    if (visible && isSuperUser) {
      dispatch(fetchCustomersList())
    }
  }, [visible, isSuperUser, dispatch])

  useEffect(() => {
    if (visible) {
      dispatch(fetchUsersList({ customer: currentCustomer }))
    }
  }, [visible, dispatch, currentCustomer])

  const formFieldsChangeHandler = (changedFields) => {
    const customerFields = changedFields.filter(
      (changedField) => changedField.name[0] === 'customer'
    )

    if (customerFields.length > 0) {
      setCurrentCustomer(customerFields[0].value)
      form.resetFields(['users'])
    }
  }

  const createGroupHandler = (values) => {
    dispatch(createGroup({ ...values, customer: currentCustomer })).then(() => {
      form.resetFields()
      onDrawerClose()
    })
  }

  return (
    <ModifyDrawer
      title={'Create group'}
      footer={
        <Space size={'small'}>
          <Button onClick={onDrawerClose}>Cancel</Button>
          <Button
            type={'primary'}
            onClick={() => form.submit()}
            loading={creatingGroup}
            disabled={creatingGroup}
          >
            Create
          </Button>
        </Space>
      }
      visible={visible}
      onDrawerClose={onDrawerClose}
    >
      <GroupForm
        form={form}
        onFinish={createGroupHandler}
        usersList={usersList}
        customersList={customersList}
        defaultCustomer={currentCustomer}
        isSuperUser={isSuperUser}
        showSuperUserFields={isSuperUser}
        onFieldsChange={formFieldsChangeHandler}
      />
    </ModifyDrawer>
  )
}

export default CreateGroup
