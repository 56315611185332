import React from 'react'
import { Route, Redirect } from 'react-router-dom'

const ProtectedRoute = ({
  condition,
  path,
  component,
  exact = false,
  ...props
}) => {
  if (!condition) return <Redirect to={'/'} />

  return (
    <Route path={path} component={component} exact={exact} {...props}>
      {props.children}
    </Route>
  )
}

export default ProtectedRoute
