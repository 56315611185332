import React from 'react'
import { Tag } from 'antd'
import { GlobalOutlined } from '@ant-design/icons'

const AssetTypeTag = ({ name, ...props }) => (
  <Tag color={'lime'} icon={<GlobalOutlined />} {...props}>
    {name}
  </Tag>
)

export default AssetTypeTag
