import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Space, Form } from 'antd'

import useAuth from '../../../hooks/useAuth'
import { createRole } from '../../../store/actions/roles.actions'
import { fetchUsersList } from '../../../store/actions/users.actions'
import { fetchCustomersList } from '../../../store/actions/customers.actions'
import ModifyDrawer from '../../ModifyDrawer'
import RoleForm from '../RoleForm'

const CreateRole = ({ visible, onDrawerClose }) => {
  const dispatch = useDispatch()
  const { isSuperUser } = useAuth()
  const [form] = Form.useForm()
  const [
    currentUserData,
    usersList,
    customersList,
    creatingRole,
  ] = useSelector((state) => [
    state.users.me.data,
    state.users.list,
    state.customers.list,
    state.roles.creatingRole,
  ])
  const [currentCustomer, setCurrentCustomer] = useState(
    currentUserData.customer._id
  )

  useEffect(() => {
    if (visible && isSuperUser) {
      dispatch(fetchCustomersList())
    }
  }, [dispatch, visible, isSuperUser])

  useEffect(() => {
    if (visible) {
      dispatch(fetchUsersList({ customer: currentCustomer }))
    }
  }, [visible, dispatch, currentCustomer])

  const formFieldsChangeHandler = (changedFields) => {
    const customerFields = changedFields.filter(
      (changedField) => changedField.name[0] === 'customer'
    )

    if (customerFields.length > 0) {
      setCurrentCustomer(customerFields[0].value)
      form.resetFields(['users'])
    }
  }

  const createRoleHandler = (values) => {
    dispatch(createRole(values)).then(() => {
      form.resetFields()
      onDrawerClose()
    })
  }

  return (
    <ModifyDrawer
      title={'Create role'}
      footer={
        <Space size={'small'}>
          <Button onClick={onDrawerClose}>Cancel</Button>
          <Button
            type={'primary'}
            onClick={() => form.submit()}
            loading={creatingRole}
            disabled={creatingRole}
          >
            Create
          </Button>
        </Space>
      }
      visible={visible}
      onDrawerClose={onDrawerClose}
    >
      <RoleForm
        form={form}
        onFinish={createRoleHandler}
        usersList={usersList}
        customersList={customersList}
        defaultCustomer={currentCustomer}
        showSuperUserFields={isSuperUser}
        onFieldsChange={formFieldsChangeHandler}
      />
    </ModifyDrawer>
  )
}

export default CreateRole
