import React from 'react'
import { Modal, Form, Input, Typography, Button } from 'antd'

import classes from './ConfirmDeleteModal.less'

const ConfirmDeleteModal = ({
  visible,
  onModalClose = () => {},
  onConfirmDelete = () => {},
  deleting = false,
  magicPhrase = '',
}) => {
  const [form] = Form.useForm()

  return (
    <Modal
      title={'Are you sure?'}
      visible={visible}
      onCancel={onModalClose}
      footer={[
        <Button onClick={onModalClose}>Cancel</Button>,
        <Button
          type={'primary'}
          onClick={() => form.submit()}
          disabled={deleting}
          loading={deleting}
          danger
        >
          Confirm Delete
        </Button>,
      ]}
    >
      <Typography.Paragraph>
        Are you sure you want to delete{' '}
        <Typography.Text strong>{magicPhrase}</Typography.Text> and every item
        connected to it.{' '}
        <Typography.Text style={{ color: 'red' }} strong>
          This action can not be undone!
        </Typography.Text>
      </Typography.Paragraph>
      <Form
        layout={'vertical'}
        form={form}
        onFinish={() => onConfirmDelete(visible)}
      >
        <Form.Item
          name={'typedMagicPhrase'}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (value && magicPhrase === value) {
                  return Promise.resolve()
                }
                return Promise.reject(
                  'Type the name of the item to be deleted!'
                )
              },
            }),
          ]}
          label={
            <span>
              In order to continue, type{' '}
              <Typography.Text code>{magicPhrase}</Typography.Text> below.
            </span>
          }
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default ConfirmDeleteModal
