import { createReducer } from '@reduxjs/toolkit'
import _ from 'lodash'

import {
  fetchWorkOrdersList,
  fetchWorkOrdersDetails,
  createWorkOrder,
  updateWorkOrder,
  deleteWorkOrder,
  addCommentToWorkOrder,
  markCompleted,
} from '../actions/workOrders.actions'

const initialState = {
  list: {
    loading: false,
    data: [],
  },
  details: {
    loading: false,
    data: null,
  },
  creatingWorkOrder: false,
  updatingWorkOrder: false,
  deletingWorkOrder: false,
  addingCommentToWorkOrder: false,
  markingCompleted: false,
}

const workOrdersReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchWorkOrdersList.pending, (state, action) => {
      state.list.data = []
      state.list.loading = true
    })
    .addCase(fetchWorkOrdersList.fulfilled, (state, action) => {
      state.list.data = action.payload
      state.list.loading = false
    })
    .addCase(fetchWorkOrdersDetails.pending, (state, action) => {
      state.details.data = null
      state.details.loading = true
    })
    .addCase(fetchWorkOrdersDetails.fulfilled, (state, action) => {
      state.details.data = action.payload
      state.details.loading = false
    })
    .addCase(createWorkOrder.pending, (state, action) => {
      state.creatingWorkOrder = true
    })
    .addCase(createWorkOrder.fulfilled, (state, action) => {
      state.list.data.push(action.payload)

      state.creatingWorkOrder = false
    })
    .addCase(updateWorkOrder.pending, (state, action) => {
      state.updatingWorkOrder = true
    })
    .addCase(updateWorkOrder.fulfilled, (state, action) => {
      const updatedIndex = _.findIndex(state.list.data, [
        '_id',
        action.payload._id,
      ])

      // Update the list
      state.list.data[updatedIndex] = action.payload
      // Update the selected item
      state.details.data = action.payload

      state.updatingWorkOrder = false
    })
    .addCase(deleteWorkOrder.pending, (state, action) => {
      state.deletingWorkOrder = true
    })
    .addCase(deleteWorkOrder.fulfilled, (state, action) => {
      const deletedIndex = _.findIndex(state.list.data, ['_id', action.payload])

      // Delete from the list
      state.list.data.splice(deletedIndex, 1)

      // Delete if selected
      if (state.details.data?._id === action.payload) {
        state.details.data = null
      }

      state.deletingWorkOrder = false
    })
    .addCase(addCommentToWorkOrder.pending, (state, action) => {
      state.addingCommentToWorkOrder = true
    })
    .addCase(addCommentToWorkOrder.fulfilled, (state, action) => {
      state.details.data = action.payload

      // Find in list
      const updatedIndex = _.findIndex(state.list.data, [
        '_id',
        action.payload._id,
      ])
      if (updatedIndex !== -1) {
        state.list.data[updatedIndex] = action.payload
      }

      state.addingCommentToWorkOrder = false
    })
    .addCase(markCompleted.pending, (state, action) => {
      state.markingCompleted = true
    })
    .addCase(markCompleted.fulfilled, (state, action) => {
      state.details.data = action.payload

      // Find in list
      const updatedIndex = _.findIndex(state.list.data, [
        '_id',
        action.payload._id,
      ])
      if (updatedIndex !== -1) {
        state.list.data[updatedIndex] = action.payload
      }

      state.markingCompleted = false
    })
})

export default workOrdersReducer
