import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Space, Form } from 'antd'

import useAuth from '../../../hooks/useAuth'
import { createProject } from '../../../store/actions/projects.actions'
import { fetchCustomersList } from '../../../store/actions/customers.actions'
import { fetchAssetTypesList } from '../../../store/actions/assetTypes.actions'
import ModifyDrawer from '../../ModifyDrawer'
import ProjectForm from '../ProjectForm'

const CreateProject = ({ visible, onDrawerClose }) => {
  const dispatch = useDispatch()
  const { isSuperUser } = useAuth()
  const [form] = Form.useForm()
  const [
    currentUserData,
    customersList,
    assetTypesList,
    creatingProject,
  ] = useSelector((state) => [
    state.users.me.data,
    state.customers.list,
    state.assetTypes.list,
    state.projects.creatingProject,
  ])
  const [currentCustomer, setCurrentCustomer] = useState(
    currentUserData.customer._id
  )

  useEffect(() => {
    if (visible && isSuperUser) {
      dispatch(fetchCustomersList())
    }
  }, [dispatch, visible, isSuperUser])

  useEffect(() => {
    if (visible) {
      dispatch(fetchAssetTypesList({ customer: currentCustomer }))
    }
  }, [dispatch, visible, currentCustomer])

  const formFieldsChangeHandler = (changedFields) => {
    const customerFields = changedFields.filter(
      (changedField) => changedField.name[0] === 'customer'
    )

    if (customerFields.length > 0) {
      setCurrentCustomer(customerFields[0].value)
      form.resetFields(['assetConfigurations'])
    }
  }

  const createProjectHandler = (values) => {
    dispatch(createProject(values)).then(() => {
      form.resetFields()
      onDrawerClose()
    })
  }

  return (
    <ModifyDrawer
      title={'Create Project'}
      footer={
        <Space size={'small'}>
          <Button onClick={onDrawerClose}>Cancel</Button>
          <Button
            type={'primary'}
            onClick={() => form.submit()}
            loading={creatingProject}
            disabled={creatingProject}
          >
            Create
          </Button>
        </Space>
      }
      visible={visible}
      onDrawerClose={onDrawerClose}
    >
      <ProjectForm
        form={form}
        onFinish={createProjectHandler}
        assetTypesList={assetTypesList}
        customersList={customersList}
        defaultCustomer={currentCustomer}
        showSuperUserFields={isSuperUser}
        onFieldsChange={formFieldsChangeHandler}
      />
    </ModifyDrawer>
  )
}

export default CreateProject
