import React from 'react'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Divider, Select } from 'antd'
import {
  AppstoreFilled,
  UserOutlined,
  FileTextOutlined,
  IdcardOutlined,
  DownOutlined,
} from '@ant-design/icons'

import classes from './ApplicationPicker.less'
import * as APP_TYPES from '../../constants/applicationTypes'
import { applicationChange } from '../../store/actions/application.actions'

const ApplicationPicker = ({ isMobile = false }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [activeApplication] = useSelector((state) => [state.application.active])

  const selectApplicationHandler = (value) => {
    if (activeApplication === value) {
      history.push('/')

      return
    }

    dispatch(applicationChange(value))

    history.push('/')
  }

  const options = [
    {
      label: 'Organization',
      value: APP_TYPES.ORGANIZATION_MANAGEMENT,
    },
    // {
    //   label: 'Resources',
    //   value: APP_TYPES.RESOURCES,
    // },
    // {
    //   label: 'Work Orders',
    //   value: APP_TYPES.WORK_ORDER_MANAGEMENT,
    // },
  ]

  return (
    <>
      <Select
        defaultValue={activeApplication}
        options={options}
        size={'large'}
        bordered={false}
        onSelect={selectApplicationHandler}
        className={clsx(classes.Select, isMobile && classes.Mobile)}
        suffixIcon={null} // <DownOutlined style={{ color: isMobile ? 'black' : 'white' }} />
        dropdownMatchSelectWidth={false}
      />
      {isMobile && <Divider />}
    </>
  )
}

export default ApplicationPicker
