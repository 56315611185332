import backendInstance from './backendInstance'

export const list = async (data, requestProps) => {
  const { data: responseData } = await backendInstance.get(
    '/res/cloudProvider',
    {
      headers: {
        Authorization: `Bearer ${requestProps.token}`,
      },
      params: {
        provider: data.provider,
        customer: data.customer,
        limit: requestProps.limit,
        page: requestProps.page,
        order: requestProps.order,
      },
    }
  )

  return responseData
}

export const details = async (data, requestProps) => {
  const { data: responseData } = await backendInstance.get(
    `/res/cloudProvider/${data.id}`,
    {
      headers: {
        Authorization: `Bearer ${requestProps.token}`,
      },
      params: {
        customer: data.customerId,
        findForCustomer: !!data.customerId,
      },
    }
  )

  return responseData
}

export const me = async (data, requestProps) => {
  const { data: responseData } = await backendInstance.get(
    '/res/cloudProvider/me',
    {
      headers: {
        Authorization: `Bearer ${requestProps.token}`,
      },
    }
  )

  return responseData
}

export const create = async (data, requestProps) => {
  const { data: responseData } = await backendInstance.post(
    '/res/cloudProvider',
    {
      name: data.name,
      code: data.code,
      customer: data.customer,
      provider: data.provider,
      settings: data.settings,
    },
    {
      headers: {
        Authorization: `Bearer ${requestProps.token}`,
      },
    }
  )

  return responseData
}

export const updateSettings = async ({ id, settings }, requestProps) => {
  const { data: responseData } = await backendInstance.post(
    `/res/cloudProvider/settings/${id}`,
    settings,
    {
      headers: {
        Authorization: `Bearer ${requestProps.token}`,
      },
    }
  )

  return responseData
}

export const health = async (data, requestProps) => {
  const { data: responseData } = await backendInstance.post(
    '/res/cloudProvider/health',
    null,
    {
      headers: {
        Authorization: `Bearer ${requestProps.token}`,
      },
    }
  )

  return responseData
}

export const sync = async (data, requestProps) => {
  const { data: responseData } = await backendInstance.post(
    '/res/cloudProvider/sync',
    null,
    {
      headers: {
        Authorization: `Bearer ${requestProps.token}`,
      },
    }
  )

  return responseData
}

export const deleteCloudProvider = async (id, requestProps) => {
  const { data: responseData } = await backendInstance.delete(
    `/res/cloudProvider/${id}`,
    {
      headers: {
        Authorization: `Bearer ${requestProps.token}`,
      },
    }
  )

  return responseData
}
