import axiosBackendInstance from './backendInstance'

export const list = async (data, requestProps) => {
  const { data: responseData } = await axiosBackendInstance.get(
    '/fb/customer',
    {
      headers: {
        Authorization: `Bearer ${requestProps.token}`,
      },
      params: {
        name: data.name,
        contactEmail: data.contactEmail,
        limit: requestProps.limit,
        page: requestProps.page,
        orderField: requestProps.orderField,
        orderDirection: requestProps.orderDirection,
      },
    }
  )

  return responseData
}

export const details = async (data, requestProps) => {
  const { data: responseData } = await axiosBackendInstance.get(
    `/fb/customer/${data.id}`,
    {
      headers: {
        Authorization: `Bearer ${requestProps.token}`,
      },
    }
  )

  return responseData
}

export const me = async (data, requestProps) => {
  const { data: responseData } = await axiosBackendInstance.get(
    '/fb/customer/me',
    {
      headers: {
        Authorization: `Bearer ${requestProps.token}`,
      },
    }
  )

  return responseData
}

export const create = async (data, requestProps) => {
  const { data: responseData } = await axiosBackendInstance.post(
    '/fb/customer',
    data,
    {
      headers: {
        Authorization: `Bearer ${requestProps.token}`,
      },
    }
  )

  return responseData
}

export const update = async (data, requestProps) => {
  const { data: responseData } = await axiosBackendInstance.put(
    `/fb/customer/${data.id}`,
    data.data,
    {
      headers: {
        Authorization: `Bearer ${requestProps.token}`,
      },
    }
  )

  return responseData
}

export const deleteCustomer = async (id, requestProps) => {
  await axiosBackendInstance.delete(`/fb/customer/${id}`, {
    headers: {
      Authorization: `Bearer ${requestProps.token}`,
    },
  })

  return id
}

export const updateSettings = async ({ id, data }, requestProps) => {
  const { data: responseData } = await axiosBackendInstance.put(
    `/fb/customer/updateSettings/${id}`,
    {
      settings: data,
    },
    {
      headers: {
        Authorization: `Bearer ${requestProps.token}`,
      },
    }
  )

  return responseData
}

export const updateResourceProvider = async ({ id, data }, requestProps) => {
  const { data: responseData } = await axiosBackendInstance.put(
    `/fb/customer/updateResourceProvider/${id}`,
    {
      documentProviderType: data.documentProviderType,
    },
    {
      headers: {
        Authorization: `Bearer ${requestProps.token}`,
      },
    }
  )

  return responseData
}
