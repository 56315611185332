const STATUS_TYPES = {
  UNKNOWN: 1,
  PENDING: 2,
  APPROVED: 3,
  REVOKED: 4,
  NOTAPPROVED: 5,
  EXPIRED: 6,
  LIMITEXCEEDED: 7,
}

export default STATUS_TYPES
