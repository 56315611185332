import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import {} from 'antd'

import RestrictedAccessLayout from '../RestrictedAccessLayout'
import SignIn from '../../pages/SignIn'
import ForgotPassword from '../../pages/ForgotPassword'
import ResetPassword from '../../pages/ResetPassword'

const UnauthorizedRoutes = () => {
  return (
    <RestrictedAccessLayout>
      <Switch>
        <Route path={'/signin'} component={SignIn} />
        <Route path={'/forgotpassword'} component={ForgotPassword} />
        <Route path={'/resetpassword/:token'} component={ResetPassword} />
        <Redirect to={'/signin'} />
      </Switch>
    </RestrictedAccessLayout>
  )
}

export default UnauthorizedRoutes
