import { createAsyncThunk } from '@reduxjs/toolkit'
import { message } from 'antd'

import * as projectsApi from '../../axios/projects'

export const fetchProjectsList = createAsyncThunk(
  'projects/list',
  async ({ customer, name, limit, page, order } = {}, thunkAPI) => {
    const response = await projectsApi.list(
      { customer, name },
      { token: thunkAPI.getState().auth.token, limit, page, order }
    )

    return response
  }
)

export const fetchProjectsDetails = createAsyncThunk(
  'projects/details',
  async (id, thunkAPI) => {
    const response = await projectsApi.details(
      { id },
      { token: thunkAPI.getState().auth.token }
    )

    return response
  }
)

export const createProject = createAsyncThunk(
  'projects/create',
  async (data, thunkAPI) => {
    const response = await projectsApi.create(data, {
      token: thunkAPI.getState().auth.token,
    })

    message.success('Project created!')

    return response
  }
)

export const updateProject = createAsyncThunk(
  'projects/update',
  async ({ id, data }, thunkAPI) => {
    const response = await projectsApi.update(
      { id, data },
      { token: thunkAPI.getState().auth.token }
    )

    message.success('Project updated!')

    return response
  }
)

export const deleteProject = createAsyncThunk(
  'projects/delete',
  async (id, thunkAPI) => {
    const response = await projectsApi.deleteProject(id, {
      token: thunkAPI.getState().auth.token,
    })

    message.success('Project deleted!')

    return response
  }
)
