import { createAsyncThunk } from '@reduxjs/toolkit'

import * as devicesApi from '../../axios/devices'
import { showMessage } from './messages.actions'

export const fetchDevicesList = createAsyncThunk(
  'devices/list',
  async (
    { customer, uuid, name, limit, page, orderField, orderDirection } = {},
    thunkAPI
  ) => {
    const response = await devicesApi.list(
      { customer, uuid, name },
      {
        token: thunkAPI.getState().auth.token,
        limit,
        page,
        orderField,
        orderDirection,
      }
    )

    return response
  }
)

export const deleteDevice = createAsyncThunk(
  'devices/delete',
  async (id, thunkAPI) => {
    const response = await devicesApi.deleteDevice(id, {
      token: thunkAPI.getState().auth.token,
    })

    thunkAPI.dispatch(
      showMessage({ type: 'success', config: { content: 'Device removed!' } })
    )

    return response
  }
)
