import React, { useEffect } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Table, Space, Pagination } from 'antd'

import classes from './List.less'
import { fetchWorkOrdersList } from '../../../store/actions/workOrders.actions'
import useAuth from '../../../hooks/useAuth'
import useTableActions from '../../../hooks/useTableActions'
import TimeToDueDateIcon from '../TimeToDueDateIcon'
import PriorityIcon from '../PriorityIcon'
import StatusIcon from '../StatusIcon'
import AssignedToTags from '../AssignedToTags'
import ProjectTag from '../../Project/ProjectTag'
import CustomerTag from '../../Customer/CustomerTag'

const ListWorkOrders = ({
  onlyProjectId = null,
  onlyAssignedTo = null,
  showProjectColumn = true,
  tableProps = {},
}) => {
  const dispatch = useDispatch()
  const { isSuperUser } = useAuth()
  const { getAlphabeticalSorterProps } = useTableActions()
  const [workOrdersList] = useSelector((state) => [state.workOrders.list])

  useEffect(() => {
    dispatch(
      fetchWorkOrdersList({
        project: onlyProjectId,
        assignedTo: onlyAssignedTo,
      })
    )
  }, [dispatch, onlyProjectId, onlyAssignedTo])

  const columns = [
    {
      title: 'Priority',
      dataIndex: 'priority',
      align: 'center',
      width: 95,
      render: (priorityLevel) => <PriorityIcon priorityLevel={priorityLevel} />,
      sorter: (a, b) => a.priority - b.priority,
      sortDirections: ['ascend', 'descend'],
      defaultSortOrder: 'descend',
    },
    {
      title: 'Status',
      dataIndex: 'completed',
      align: 'center',
      width: 100,
      filters: [
        {
          text: 'In progress',
          value: false,
        },
        {
          text: 'Completed',
          value: true,
        },
      ],
      onFilter: (value, record) => record.completed === value,
      render: (completed, record) => (
        <StatusIcon completed={completed} startAt={record.startAt} />
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Due at',
      dataIndex: 'dueAt',
      render: (dueAt, record) =>
        record.completed ? (
          moment(dueAt).format('MM/DD/Y')
        ) : (
          <TimeToDueDateIcon deadline={dueAt} />
        ),
    },
    {
      title: 'Assigned to',
      dataIndex: 'assignedTo',
      render: (name, record) => (
        <AssignedToTags
          users={record.assignedToUsers}
          groups={record.assignedToGroups}
        />
      ),
    },
    {
      title: 'Project',
      dataIndex: ['project', 'name'],
      render: (name) => <ProjectTag name={name} />,
      className: showProjectColumn ? null : classes.Hide,
    },

    {
      title: 'Customer',
      dataIndex: ['customer', 'name'],
      className: isSuperUser ? null : 'fb-column-hidden',
      render: (customer) => <CustomerTag name={customer} />,
      ...getAlphabeticalSorterProps(),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (text, record) => (
        <Space>
          <a>
            <Link to={`/workOrders/details/${record._id}`}>View</Link>
          </a>
        </Space>
      ),
    },
  ]

  return (
    <Table
      columns={columns}
      dataSource={workOrdersList.data}
      tableLayout={'auto'}
      loading={workOrdersList.loading}
      rowKey={'_id'}
      scroll={{ x: 900 }}
      pagination={{
        pageSizeOptions: ['10', '20', '30', '50'],
        showSizeChanger: true,
      }}
      {...tableProps}
    />
  )
}

export default ListWorkOrders
