import { createReducer } from '@reduxjs/toolkit'
import _ from 'lodash'

import {
  fetchUsersDetails,
  fetchUsersList,
  fetchUsersMe,
  createUser,
  updateUser,
  deleteUser,
  bulkDeleteUsers,
  forgotPassword,
  changePassword,
  resetPassword,
  clearMe,
} from '../actions/users.actions'

const initialState = {
  list: {
    loading: false,
    data: [],
    total: 0,
  },
  details: {
    loading: false,
    data: null,
  },
  me: {
    loading: false,
    data: null,
  },
  myCredentials: {
    isSuperUser: false,
    myActions: [],
  },
  creatingUser: false,
  updatingUser: false,
  deletingUser: false,
  bulkDeletingUsers: false,
  forgotPasswordSending: false,
  changingPassword: false,
  resettingPassword: false,
}

const usersReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchUsersList.pending, (state, action) => {
      state.list.loading = true
      state.list.data = []
    })
    .addCase(fetchUsersList.fulfilled, (state, action) => {
      state.list.loading = false
      state.list.data = action.payload.list || action.payload || []
      state.list.total = action.payload.total
    })
    .addCase(fetchUsersDetails.pending, (state, action) => {
      state.details.loading = true
      state.details.data = null
    })
    .addCase(fetchUsersDetails.fulfilled, (state, action) => {
      state.details.loading = false
      state.details.data = action.payload
    })
    .addCase(fetchUsersMe.pending, (state, action) => {
      state.me.loading = true
      state.me.data = null
    })
    .addCase(fetchUsersMe.fulfilled, (state, action) => {
      state.me.data = action.payload

      // Extract the access data
      let combinedActions = []
      action.payload.roles.forEach(
        (role) => (combinedActions = _.concat(combinedActions, role.actions))
      )
      combinedActions = _.uniq(combinedActions)

      state.myCredentials.isSuperUser = action.payload.superuser
      state.myCredentials.myActions = combinedActions

      state.me.loading = false
    })
    .addCase(createUser.pending, (state, action) => {
      state.creatingUser = true
    })
    .addCase(createUser.fulfilled, (state, action) => {
      state.list.data.push(action.payload)
      state.creatingUser = false
    })
    .addCase(createUser.rejected, (state, action) => {
      state.creatingUser = false
    })
    .addCase(updateUser.pending, (state, action) => {
      state.updatingUser = true
    })
    .addCase(updateUser.fulfilled, (state, action) => {
      const updatedIndex = _.findIndex(state.list.data, [
        '_id',
        action.payload._id,
      ])

      // Update the list
      state.list.data[updatedIndex] = action.payload
      // Update the selected item
      state.details.data = action.payload
      // If the user is me, update me
      if (state.me.data._id === action.payload._id) {
        state.me.data = action.payload
      }

      state.updatingUser = false
    })
    .addCase(updateUser.rejected, (state, action) => {
      state.updatingUser = false
    })
    .addCase(deleteUser.pending, (state, action) => {
      state.deletingUser = true
    })
    .addCase(deleteUser.fulfilled, (state, action) => {
      const deletedIndex = _.findIndex(state.list.data, ['_id', action.payload])

      // Delete from the list
      state.list.data.splice(deletedIndex, 1)

      // Delete if selected
      if (state.details.data?._id === action.payload) {
        state.details.data = null
      }

      state.deletingUser = false
    })
    .addCase(deleteUser.rejected, (state, action) => {
      state.deletingUser = false
    })
    .addCase(bulkDeleteUsers.pending, (state, action) => {
      state.bulkDeletingUsers = true
    })
    .addCase(bulkDeleteUsers.fulfilled, (state, action) => {
      // Remove from list
      state.list.data = state.list.data.filter((item) => {
        return _.findIndex(action.payload, (a) => a === item._id) === -1
      })
      // Remove if selected
      if (
        _.findIndex(action.payload, (a) => a === state.details.data?._id) > -1
      ) {
        state.details.data = null
      }

      state.bulkDeletingUsers = false
    })
    .addCase(bulkDeleteUsers.rejected, (state, action) => {
      state.bulkDeletingUsers = false
    })
    .addCase(forgotPassword.pending, (state, action) => {
      state.forgotPasswordSending = true
    })
    .addCase(forgotPassword.fulfilled, (state, action) => {
      state.forgotPasswordSending = false
    })
    .addCase(forgotPassword.rejected, (state, action) => {
      state.forgotPasswordSending = false
    })
    .addCase(changePassword.pending, (state, action) => {
      state.changingPassword = true
    })
    .addCase(changePassword.fulfilled, (state, action) => {
      state.changingPassword = false
    })
    .addCase(changePassword.rejected, (state, action) => {
      state.changingPassword = false
    })
    .addCase(resetPassword.pending, (state, action) => {
      state.resettingPassword = true
    })
    .addCase(resetPassword.fulfilled, (state, action) => {
      state.resettingPassword = false
    })
    .addCase(resetPassword.rejected, (state, action) => {
      state.resettingPassword = false
    })
    .addCase(clearMe, (state, action) => {
      state.me = {
        data: null,
        loading: false,
      }
      state.myCredentials = {
        isSuperUser: false,
        myActions: [],
      }
    })
})

export default usersReducer
