import React, { useState } from 'react'
import { Bar } from 'react-chartjs-2'
import moment from 'moment'
import _ from 'lodash'
import { Card, Select, Space } from 'antd'

const OnTimeVsOverdueChart = ({ labels, monthsSpan }) => {
  return (
    <Bar
      height={350}
      options={{
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              stacked: true,
              ticks: {
                beginAtZero: true,
              },
            },
          ],
          xAxes: [
            {
              stacked: true,
            },
          ],
        },
      }}
      data={{
        labels: _.reverse(labels),
        datasets: [
          {
            label: 'Completed on time',
            backgroundColor: 'rgba(22, 211, 127, 0.4)',
            borderColor: 'rgba(22, 211, 127, 0.8)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(22, 211, 127, 0.33)',
            hoverBorderColor: 'rgba(22, 211, 127, 1)',
            data: _.map(Array(monthsSpan), () =>
              Math.floor(Math.random() * 100)
            ),
          },
          {
            label: 'Completed overdue',
            backgroundColor: 'rgba(237, 41, 57, 0.4)',
            borderColor: 'rgba(237, 41, 57, 0.8)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(237, 41, 57, 0.33)',
            hoverBorderColor: 'rgba(237, 41, 57, 1)',
            data: _.map(Array(monthsSpan), () =>
              Math.floor(Math.random() * 100)
            ),
          },
          {
            label: 'In progress',
            backgroundColor: 'rgba(33, 150, 243, 0.4)',
            borderColor: 'rgba(33, 150, 243, 0.8)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(33, 150, 243, 0.33)',
            hoverBorderColor: 'rgba(33, 150, 243, 1)',
            data: [..._.fill(Array(monthsSpan - 1), 0), 33],
          },
        ],
      }}
    />
  )
}

export default OnTimeVsOverdueChart
