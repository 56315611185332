import React, { useState } from 'react'
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Col, Divider, Row, Switch, Form } from 'antd'

import SignOutModal from '../SignOutModal'
import {
  updateCloudProviderSettings,
  deleteCloudProvider,
} from '../../../../store/actions/cloudProviders.actions'

const ProviderSettings = ({
  form,
  onFinish,
  onValuesChange = () => {},
  onMakeFormPure = () => {},
  isSuperUser = false,
  disableSignInForSuperUser = false,
  providerId = '',
  providerName = '',
  providerURL = '',
  cloudProvider = null,
}) => {
  const dispatch = useDispatch()
  const [deletingCloudProvider] = useSelector((state) => [
    state.cloudProviders.deletingCloudProvider,
  ])
  const [showSignOutModal, setShowSignOutModal] = useState(false)

  const signInHandler = (values) => {
    onFinish({
      name: providerName,
      provider: providerId,
      settings: {
        fullySetup: false,
      },
    })
  }

  const updateSettingsHandler = (values) => {
    dispatch(
      updateCloudProviderSettings({
        id: cloudProvider._id,
        settings: {
          ...values,
          // sendTokensWithApproval: checked,
        },
      })
    ).then((thunkResult) => {
      if (thunkResult.meta.requestStatus !== 'rejected') {
        onMakeFormPure()
      }
    })
  }

  const deleteCloudProviderHandler = () => {
    dispatch(
      deleteCloudProvider({ id: cloudProvider._id, superuser: isSuperUser })
    )
  }

  let signInButton = null
  let updateSettingsForm = null
  if (_.isEmpty(cloudProvider) || !cloudProvider.settings.fullySetup) {
    // There is no Cloud Provider
    signInButton = (
      <Form form={form} onFinish={signInHandler}>
        <Button
          disabled={disableSignInForSuperUser}
          type={'primary'}
          size={'large'}
          onClick={() => window.open(providerURL, '_self')}
        >
          Sign-in into {providerName}
        </Button>
      </Form>
    )
  } else {
    // Cloud Provider present
    updateSettingsForm = (
      <>
        <Divider />
        <Form
          form={form}
          onFinish={updateSettingsHandler}
          onValuesChange={onValuesChange}
          initialValues={{
            sendTokensWithApproval:
              cloudProvider?.settings?.sendTokensWithApproval,
          }}
        >
          <Row gutter={[10, 60]}>
            <Col>
              <Form.Item
                name={'sendTokensWithApproval'}
                valuePropName={'checked'}
                label={`Provide automatic access to ${providerName} account for all FieldMap users`}
                colon={false}
              >
                <Switch />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                disabled={deletingCloudProvider}
                loading={deletingCloudProvider}
                onClick={() => setShowSignOutModal(true)}
                danger
              >
                Sign-out from {providerName}
              </Button>
            </Col>
          </Row>
        </Form>
      </>
    )
  }

  return (
    <>
      <SignOutModal
        visible={showSignOutModal}
        onModalClose={() => setShowSignOutModal(false)}
        cloudProviderName={cloudProvider?.name}
        onSignOut={deleteCloudProviderHandler}
      />
      {signInButton}
      {updateSettingsForm}
    </>
  )
}

export default ProviderSettings
