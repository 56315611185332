import React from 'react'
import { Image } from 'antd'

import logo from '../../assets/images/logo.png'

const Logo = (props) => {
  return <Image src={logo} alt={'FieldBox Logo'} preview={false} {...props} />
  // return <Typography.Title level={3}>FieldBox Logo</Typography.Title>
}

export default Logo
