import React from 'react'
import { Col, Row } from 'antd'

import classes from './Widgets.less'
import Widget from '../Widget'

const Widgets = ({ widgetList }) => {
  return (
    <Row gutter={[16, 16]}>
      {widgetList.map((widget, index) => (
        <Col span={24} key={index}>
          <Widget
            title={widget.title}
            value={widget.value}
            percent={widget.percent}
          />
        </Col>
      ))}
    </Row>
  )
}

export default Widgets
