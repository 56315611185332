import { createAsyncThunk } from '@reduxjs/toolkit'

import * as rolesApi from '../../axios/roles'
import { showMessage } from './messages.actions'

export const fetchRolesList = createAsyncThunk(
  'roles/list',
  async (
    { customer, name, page, limit, orderField, orderDirection } = {},
    thunkAPI
  ) => {
    const response = await rolesApi.list(
      { customer, name },
      {
        token: thunkAPI.getState().auth.token,
        page,
        limit,
        orderField,
        orderDirection,
      }
    )

    return response
  }
)

export const fetchRolesDetails = createAsyncThunk(
  'roles/details',
  async (id, thunkAPI) => {
    const response = await rolesApi.details(
      { id },
      { token: thunkAPI.getState().auth.token }
    )

    const actionsObject = {}
    response.actions.forEach((action) => (actionsObject[action] = true))

    response.actions = actionsObject

    return response
  }
)

export const createRole = createAsyncThunk(
  'roles/create',
  async (data, thunkAPI) => {
    // Transform the actions object into an array
    data.actions = Object.keys(data.actions).filter(
      (action) => data.actions[action]
    )

    const response = await rolesApi.create(data, {
      token: thunkAPI.getState().auth.token,
    })

    thunkAPI.dispatch(
      showMessage({ type: 'success', config: { content: 'Role created!' } })
    )

    return response
  }
)

export const updateRole = createAsyncThunk(
  'roles/update',
  async ({ id, data }, thunkAPI) => {
    // Transform the actions object into an array
    data.actions = Object.keys(data.actions).filter(
      (action) => data.actions[action]
    )

    const response = await rolesApi.update(
      { id, data },
      { token: thunkAPI.getState().auth.token }
    )

    thunkAPI.dispatch(
      showMessage({ type: 'success', config: { content: 'Role updated!' } })
    )

    return response
  }
)

export const deleteRole = createAsyncThunk(
  'roles/delete',
  async (id, thunkAPI) => {
    const response = await rolesApi.deleteRole(id, {
      token: thunkAPI.getState().auth.token,
    })

    thunkAPI.dispatch(
      showMessage({ type: 'success', config: { content: 'Role deleted!' } })
    )

    return response
  }
)

export const bulkDeleteRoles = createAsyncThunk(
  'roles/bulkDelete',
  async (ids, thunkAPI) => {
    const response = await rolesApi.bulkDeleteRoles(ids, {
      token: thunkAPI.getState().auth.token,
    })

    thunkAPI.dispatch(
      showMessage({ type: 'success', config: { content: 'Roles deleted!' } })
    )

    return response
  }
)
