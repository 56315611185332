import axiosBackendInstance from './backendInstance'

export const list = async (data, requestProps) => {
  const { data: responseData } = await axiosBackendInstance.get(
    '/res/resource',
    {
      headers: {
        Authorization: `Bearer ${requestProps.token}`,
      },
      params: {
        prefix: data.prefix,
      },
    }
  )

  return responseData
}

export const details = async (data, requestProps) => {
  const { data: responseData } = await axiosBackendInstance.get(
    `/api/assets/${data.id}`,
    {
      headers: {
        Authorization: `Bearer ${requestProps.token}`,
      },
    }
  )

  return responseData
}

export const createFile = async (data, requestProps) => {
  const { data: responseData } = await axiosBackendInstance.post(
    '/api/assets',
    data,
    {
      headers: {
        Authorization: `Bearer ${requestProps.token}`,
      },
    }
  )

  return responseData
}

export const createFolder = async (data, requestProps) => {
  const { data: responseData } = await axiosBackendInstance.post(
    '/res/resource/createFolder',
    data,
    {
      headers: {
        Authorization: `Bearer ${requestProps.token}`,
      },
    }
  )

  return responseData
}

export const update = async ({ data, id }, requestProps) => {
  const { data: responseData } = await axiosBackendInstance.put(
    `/api/assets/${id}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${requestProps.token}`,
      },
    }
  )

  return responseData
}

export const deleteResource = async ({ key, id }, requestProps) => {
  await axiosBackendInstance.post(
    `/res/resource/delete`,
    {
      resourceId: id,
      resourceKey: key,
    },
    {
      headers: {
        Authorization: `Bearer ${requestProps.token}`,
      },
    }
  )

  return id
}
