import React from 'react'
import { Row, Col, Card, Spin } from 'antd'

import classes from './ApplicationLoading.less'
import Logo from '../../components/Logo'

const ApplicationLoading = () => {
  return (
    <Row justify={'center'} align={'middle'} className={classes.Container}>
      <Spin size={'large'} spinning>
        <Card>
          <Row justify={'center'} gutter={[8, 16]}>
            <Col>
              <Logo />
            </Col>
          </Row>
        </Card>
      </Spin>
    </Row>
  )
}

export default ApplicationLoading
