import { createAsyncThunk } from '@reduxjs/toolkit'

import * as authAPI from '../../axios/auth'

import { clearMe as clearMeCustomers } from './customers.actions'
import { clearMe as clearMeUsers } from './users.actions'

export const signIn = createAsyncThunk(
  'auth/signIn',
  async ({ email, password }, thunkAPI) => {
    const response = await authAPI.signIn(email, password)

    localStorage.setItem('token', response.token)
    localStorage.setItem(
      'expirationTime',
      `${new Date().getSeconds() + 60 * 60}`
    )

    // thunkAPI.dispatch(refreshToken({ expirationTime: 60 * 60 }))
    const refreshInterval = setInterval(
      () => thunkAPI.dispatch(refreshToken()),
      60 * 60 * 1000
    )

    return {
      token: response.token,
      refreshInterval,
    }
  }
)

export const refreshToken = createAsyncThunk(
  'auth/refreshToken',
  async ({ prevToken = null, expirationTime = 60 * 60 } = {}, thunkAPI) => {
    let response
    try {
      response = await authAPI.refreshToken(
        prevToken || thunkAPI.getState().auth.token
      )
    } catch (e) {
      thunkAPI.dispatch(signOut())

      throw e
    }

    localStorage.setItem('token', response.token)
    localStorage.setItem(
      'expirationTime',
      `${new Date().getSeconds() + 60 * 60}`
    )

    // thunkAPI.dispatch(refreshToken({ expirationTime: 60 * 60 }))

    return response
  }
)

export const autoSignIn = createAsyncThunk(
  'users/autoSignIn',
  async (data, thunkAPI) => {
    const token = localStorage.getItem('token')
    if (!token) {
      thunkAPI.dispatch(signOut())
      return Promise.reject('No token present!')
    } //logout
    else {
      thunkAPI.dispatch(refreshToken({ prevToken: token }))

      const refreshInterval = setInterval(
        () => thunkAPI.dispatch(refreshToken()),
        60 * 60 * 1000
      )

      return { refreshInterval }
    }
  }
)

export const signOut = createAsyncThunk(
  'auth/signOut',
  async (data, thunkAPI) => {
    await localStorage.removeItem('token')
    await localStorage.removeItem('expirationTime')

    thunkAPI.dispatch(clearMeCustomers())
    thunkAPI.dispatch(clearMeUsers())
  }
)
