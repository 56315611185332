import React, { useState } from 'react'
import { Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import useAuth from '../../hooks/useAuth'
import TableContainer from '../../components/TableContainer'
import ListWorkOrders from '../../components/WorkOrder/ListWorkOrders'
import CreateWorkOrder from '../../components/WorkOrder/CreateWorkOrder'

const WorkOrders = () => {
  const { isSuperUser, hasAction, allActions } = useAuth()
  const [createWorkOrder, setCreateWorkOrder] = useState(false)

  return (
    <>
      {hasAction(allActions.adminactions) && (
        <CreateWorkOrder
          visible={createWorkOrder}
          onModalClose={() => setCreateWorkOrder(false)}
        />
      )}
      <TableContainer
        title={'Work Orders'}
        topBarActions={[
          hasAction(allActions.adminactions) && (
            <Button
              key={'0'}
              type={'primary'}
              icon={<PlusOutlined />}
              shape={'round'}
              size={'large'}
              onClick={() => setCreateWorkOrder(true)}
            >
              Add Work Order
            </Button>
          ),
        ]}
      >
        <ListWorkOrders showProjectColumn={true} />
      </TableContainer>
    </>
  )
}

export default WorkOrders
