import {
  autoSignIn,
  signIn,
  signOut,
  refreshToken,
} from '../actions/auth.actions'

import { createReducer } from '@reduxjs/toolkit'

const initialState = {
  token: null,
  refreshInterval: null,
  loading: false,
  autoSignInLoading: true,
}

const authReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(signIn.pending, (state, action) => {
      state.loading = true
    })
    .addCase(signIn.fulfilled, (state, action) => {
      state.token = action.payload.token
      state.refreshInterval = action.payload.refreshInterval
      state.loading = false
    })
    .addCase(signIn.rejected, (state, action) => {
      state.token = null
      state.loading = false
    })
    .addCase(refreshToken.fulfilled, (state, action) => {
      state.token = action.payload.token
      state.autoSignInLoading = false
    })
    .addCase(autoSignIn.fulfilled, (state, action) => {
      // state.autoSignInLoading = false
      state.refreshInterval = action.payload.refreshInterval
    })
    .addCase(autoSignIn.rejected, (state, action) => {
      state.autoSignInLoading = false
    })
    .addCase(signOut.pending, (state, action) => {
      state.token = null
      state.loading = false
    })
    .addCase(signOut.fulfilled, (state, action) => {
      clearInterval(state.refreshInterval)

      state.refreshInterval = null
      state.autoSignInLoading = false
    })
})

export default authReducer
