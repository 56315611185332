import { createAsyncThunk } from '@reduxjs/toolkit'
import { message } from 'antd'

import * as assetTypesApi from '../../axios/assetTypes'

export const fetchAssetTypesList = createAsyncThunk(
  'assetTypes/list',
  async ({ customer, name, limit, page, order } = {}, thunkAPI) => {
    const response = await assetTypesApi.list(
      {
        customer,
        name,
      },
      {
        token: thunkAPI.getState().auth.token,
        limit,
        page,
        order,
      }
    )

    return response
  }
)

export const fetchAssetTypesDetails = createAsyncThunk(
  'assetTypes/details',
  async (id, thunkAPI) => {
    const response = await assetTypesApi.details(
      { id },
      { token: thunkAPI.getState().auth.token }
    )

    return response
  }
)

export const createAssetType = createAsyncThunk(
  'assetTypes/create',
  async (data, thunkAPI) => {
    const response = await assetTypesApi.create(data, {
      token: thunkAPI.getState().auth.token,
    })

    message.success('Asset Type created!')

    return response
  }
)

export const updateAssetType = createAsyncThunk(
  'assetTypes/update',
  async ({ id, data }, thunkAPI) => {
    const response = await assetTypesApi.update(
      { id, data },
      { token: thunkAPI.getState().auth.token }
    )

    message.success('Asset Type updated!')

    return response
  }
)

export const deleteAssetType = createAsyncThunk(
  'assetTypes/delete',
  async (id, thunkAPI) => {
    const response = await assetTypesApi.deleteAssetType(id, {
      token: thunkAPI.getState().auth.token,
    })

    message.success('Asset Type deleted!')

    return response
  }
)
