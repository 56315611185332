import React from 'react'
import { Tooltip, Progress } from 'antd'

const steps = 6

const PriorityIcon = ({ priorityLevel }) => {
  let badgeColor, tooltipTitle
  // eslint-disable-next-line default-case
  switch (priorityLevel) {
    case 1:
      tooltipTitle = 'Lowest'
      badgeColor = '#16d37f'
      break
    case 2:
      tooltipTitle = 'Low'
      badgeColor = '#16d37f'
      break
    case 3:
      tooltipTitle = 'Medium'
      badgeColor = '#3f51b5'
      break
    case 4:
      tooltipTitle = 'Medium-High'
      badgeColor = '#3f51b5'
      break
    case 5:
      tooltipTitle = 'High'
      badgeColor = '#f44336'
      break
    case 6:
      tooltipTitle = 'Top'
      badgeColor = '#f44336'
      break
  }

  return (
    <Tooltip title={tooltipTitle}>
      <Progress
        steps={steps}
        percent={(priorityLevel / steps) * 100}
        strokeColor={badgeColor}
        size={'small'}
        showInfo={false}
      />
    </Tooltip>
  )
}

export default PriorityIcon
