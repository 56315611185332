import axiosBackendInstance from './backendInstance'

export const list = async (data, requestProps) => {
  const { data: responseData } = await axiosBackendInstance.get(
    '/wom/assetType',
    {
      headers: {
        Authorization: `Bearer ${requestProps.token}`,
      },
      params: {
        customer: data.customer,
        name: data.name,
        limit: requestProps.limit,
        page: requestProps.page,
        order: requestProps.order,
      },
    }
  )

  return responseData
}

export const details = async (data, requestProps) => {
  const { data: responseData } = await axiosBackendInstance.get(
    `/wom/assetType/${data.id}`,
    {
      headers: {
        Authorization: `Bearer ${requestProps.token}`,
      },
    }
  )

  return responseData
}

export const create = async (data, requestProps) => {
  const { data: responseData } = await axiosBackendInstance.post(
    '/wom/assetType',
    data,
    {
      headers: {
        Authorization: `Bearer ${requestProps.token}`,
      },
    }
  )

  return responseData
}

export const update = async ({ id, data }, requestProps) => {
  const { data: responseData } = await axiosBackendInstance.put(
    `/wom/assetType/${id}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${requestProps.token}`,
      },
    }
  )

  return responseData
}

export const deleteAssetType = async (id, requestProps) => {
  await axiosBackendInstance.delete(`/wom/assetType/${id}`, {
    headers: {
      Authorization: `Bearer ${requestProps.token}`,
    },
  })

  return id
}
