import { createReducer } from '@reduxjs/toolkit'
import { message } from 'antd'

import {
  fetchRolesList,
  fetchRolesDetails,
  createRole,
  updateRole,
  deleteRole,
  bulkDeleteRoles,
} from '../actions/roles.actions'
import _ from 'lodash'

const initialState = {
  list: {
    loading: false,
    data: [],
  },
  details: {
    loading: false,
    data: null,
  },
  creatingRole: false,
  updatingRole: false,
  deletingRole: false,
  bulkDeletingRoles: false,
}

const rolesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchRolesList.pending, (state, action) => {
      state.list.loading = true
      state.list.data = []
    })
    .addCase(fetchRolesList.fulfilled, (state, action) => {
      state.list.data = action.payload
      state.list.loading = false
    })
    .addCase(fetchRolesDetails.pending, (state, action) => {
      state.details.loading = true
      state.details.data = null
    })
    .addCase(fetchRolesDetails.fulfilled, (state, action) => {
      state.details.data = action.payload
      state.details.loading = false
    })
    .addCase(createRole.pending, (state, action) => {
      state.creatingRole = true
    })
    .addCase(createRole.fulfilled, (state, action) => {
      state.list.data.push(action.payload)

      state.creatingRole = false
    })
    .addCase(createRole.rejected, (state, action) => {
      state.creatingRole = false
    })
    .addCase(updateRole.pending, (state, action) => {
      state.updatingRole = true
    })
    .addCase(updateRole.fulfilled, (state, action) => {
      const updatedIndex = _.findIndex(state.list.data, [
        '_id',
        action.payload._id,
      ])

      // Update the list
      state.list.data[updatedIndex] = action.payload
      // Update the selected item
      state.details.data = action.payload

      state.updatingRole = false
    })
    .addCase(updateRole.rejected, (state, action) => {
      state.updatingRole = false
    })
    .addCase(deleteRole.pending, (state, action) => {
      state.deletingRole = true
    })
    .addCase(deleteRole.fulfilled, (state, action) => {
      const deletedIndex = _.findIndex(state.list.data, ['_id', action.payload])

      // Delete from the list
      state.list.data.splice(deletedIndex, 1)

      // Delete if selected
      if (state.details.data?._id === action.payload) {
        state.details.data = null
      }

      state.deletingRole = false
    })
    .addCase(deleteRole.rejected, (state, action) => {
      state.deletingRole = false
    })
    .addCase(bulkDeleteRoles.pending, (state, action) => {
      state.bulkDeletingRoles = true
    })
    .addCase(bulkDeleteRoles.fulfilled, (state, action) => {
      // Remove from the list
      state.list.data = state.list.data.filter((item) => {
        return _.findIndex(action.payload, (a) => a === item._id) === -1
      })

      // Remove if selected
      if (
        _.findIndex(action.payload, (a) => a === state.details.data?._id) > -1
      ) {
        state.details.data = null
      }

      state.bulkDeletingRoles = false
    })
    .addCase(bulkDeleteRoles.rejected, (state, action) => {
      state.bulkDeletingRoles = false
    })
    .addMatcher(
      (action) =>
        action.type.startsWith('roles/') && action.type.endsWith('/rejected'),
      (state, action) => {
        message.error(action.error.message)
      }
    )
})

export default rolesReducer
