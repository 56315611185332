import React from 'react'
import { Form, Row, Col, Input, Select, Button } from 'antd'

import AssetTypeTag from '../../AssetType/AssetTypeTag'

const ProjectForm = ({
  form,
  onFinish,
  assetTypesList,
  customersList,
  defaultCustomer,
  showSuperUserFields = false,
  onFieldsChange,
}) => {
  return (
    <Form
      layout={'vertical'}
      form={form}
      onFinish={onFinish}
      onFieldsChange={onFieldsChange}
      initialValues={{ customer: defaultCustomer }}
    >
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name={'name'}
            rules={[{ required: true, messages: 'This field is required!' }]}
            label={'Name'}
          >
            <Input placeholder={'Please provide a name for the project'} />
          </Form.Item>
        </Col>
      </Row>
      {showSuperUserFields && (
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name={'customer'}
              label={'Customer'}
              tooltip={'Super User setting'}
            >
              <Select
                loading={customersList.loading}
                disabled={customersList.loading}
                options={customersList.data.map((customer) => ({
                  label: customer.name,
                  value: customer._id,
                }))}
              />
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item name={'connectedMap'} label={'Connect with a map'}>
            <Button type={'primary'}>Select a file</Button>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item name={'assetTypes'} label={'Asset types'}>
            <Select
              loading={assetTypesList.loading}
              disabled={assetTypesList.loading}
              options={assetTypesList.data.map((user) => ({
                label: user.name,
                value: user._id,
              }))}
              tagRender={({ label, value, closable, onClose }) => (
                <AssetTypeTag
                  name={label}
                  closable={closable}
                  onClose={onClose}
                />
              )}
              mode={'multiple'}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

export default ProjectForm
