import React, { useEffect } from 'react'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import {
  Row,
  Col,
  PageHeader,
  Button,
  Grid,
  Menu,
  Dropdown,
  Descriptions,
  Divider,
  Badge,
  Card,
  Spin,
} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import {
  SettingOutlined,
  EllipsisOutlined,
  DownloadOutlined,
} from '@ant-design/icons'

import classes from './OrganizationDashboard.less'
import { fetchCustomersMe } from '../../store/actions/customers.actions'
import Widgets from '../../components/Widgets'

const OrganizationDashboard = () => {
  const screens = Grid.useBreakpoint()
  const dispatch = useDispatch()
  const history = useHistory()
  const [token, meCustomers] = useSelector((state) => [
    state.auth.token,
    state.customers.me,
  ])

  useEffect(() => {
    dispatch(fetchCustomersMe())
  }, [dispatch, token])

  // Show loading state
  if (meCustomers.loading || !meCustomers.data) {
    return (
      <Card bodyStyle={{ width: '100%', height: '100%' }}>
        <Row justify={'center'} align={'middle'} style={{ height: '100%' }}>
          <Spin />
        </Row>
      </Card>
    )
  }

  const organizationControls = [
    {
      label: 'Mobile Publisher Download',
      icon: <DownloadOutlined />,
      type: 'default',
      onClick: () =>
        window.open(
          'https://www.dropbox.com/s/q6qlgs80oyatiuq/MTMobilePublisher.exe?dl=0',
          '_blank'
        ),
    },
    // {
    //   label: 'Organization settings',
    //   icon: <SettingOutlined />,
    //   type: 'primary',
    //   onClick: () => history.push('/settings/general/me'),
    // },
  ]

  const isXs = screens.xs && !screens.sm
  const isSm = screens.sm && !screens.md
  const isMd = screens.md && !screens.lg

  let extra = null
  if (isXs || isSm || isMd) {
    extra = (
      <Dropdown
        key="more"
        overlay={
          <Menu>
            {organizationControls.map((control, index) => (
              <Menu.Item
                key={index}
                icon={control.icon}
                onSelect={control.onClick}
              >
                {control.label}
              </Menu.Item>
            ))}
          </Menu>
        }
      >
        <Button type={'text'}>
          <EllipsisOutlined
            style={{
              fontSize: 20,
              verticalAlign: 'top',
            }}
          />
        </Button>
      </Dropdown>
    )
  } else {
    extra = organizationControls.map((control, index) => (
      <Button
        key={index}
        type={control.type}
        icon={control.icon}
        onClick={control.onClick}
      >
        {control.label}
      </Button>
    ))
  }

  return (
    <>
      <Row gutter={[8, 16]}>
        <Col span={24}>
          <PageHeader
            title={'My Organization'}
            subTitle={!isXs && 'Dashboard'}
            ghost={false}
            extra={extra}
          />
        </Col>
      </Row>
      <Row gutter={[8, 16]}>
        <Col xs={24} md={16}>
          <Card>
            <Descriptions size={'large'} column={1} bordered>
              <Descriptions.Item label={'Name'}>
                {meCustomers.data?.name}
              </Descriptions.Item>
              <Descriptions.Item label={'Contact email'}>
                {meCustomers.data?.contactEmail}
              </Descriptions.Item>
              <Descriptions.Item label={'License expiration date'}>
                {meCustomers.data?.validUntil
                  ? moment(meCustomers.data?.validUntil).format('MM/DD/Y')
                  : 'Indefinitely'}
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
        <Col xs={24} md={8}>
          <Widgets
            widgetList={[
              {
                title: 'Licences used',
                value: `${meCustomers.data?.licensesUsed}/${meCustomers.data?.maxLicenseNo}`,
                percent: Math.round(
                  (+meCustomers.data?.licensesUsed /
                    +meCustomers.data?.maxLicenseNo) *
                    100
                ),
              },
              // { title: 'Space used', value: '3/10GB', percent: 30 },
            ]}
          />
        </Col>
      </Row>
    </>
  )
}

export default OrganizationDashboard
