import React from 'react'
import {
  Form,
  Row,
  Col,
  Input,
  Select,
  Collapse,
  Checkbox,
  Tag,
  Space,
} from 'antd'

import { ACCESS_TYPES } from '../../../constants/fieldboxAccessTypes'
import UserTag from '../../User/UserTag'
import GroupTag from '../../Group/GroupTag'

const RoleForm = ({
  form,
  onFinish,
  usersList,
  customersList,
  defaultCustomer,
  showSuperUserFields = false,
  onFieldsChange,
}) => {
  return (
    <Form
      layout={'vertical'}
      form={form}
      onFinish={onFinish}
      onFieldsChange={onFieldsChange}
      initialValues={{ customer: defaultCustomer }}
    >
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name={'name'}
            rules={[{ required: true, message: 'This field is required!' }]}
            label={'Name'}
          >
            <Input placeholder={'Please provide a name for the role'} />
          </Form.Item>
        </Col>
      </Row>
      {showSuperUserFields && (
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name={'customer'}
              label={'Customer'}
              tooltip={'Super User setting'}
            >
              <Select
                loading={customersList.loading}
                disabled={customersList.loading}
                options={customersList.data.map((customer) => ({
                  label: customer.name,
                  value: customer._id,
                }))}
              />
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item name={'users'} label={'Users'}>
            <Select
              loading={usersList.loading}
              disabled={usersList.loading}
              options={usersList.data.map((user) => ({
                label: user.name,
                value: user._id,
              }))}
              tagRender={({ label, value, closable, onClose }) => (
                <UserTag name={label} closable={closable} onClose={onClose} />
              )}
              mode={'multiple'}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item label={'Role access level'}>
            <Collapse collapsible={'header'} bordered={false} ghost>
              {ACCESS_TYPES.map((action) => (
                <Collapse.Panel
                  key={action.id}
                  header={
                    <Space>
                      {action.name}
                      <Tag
                        color={action.system === 'fieldbox' ? 'blue' : 'green'}
                      >
                        {action.system.toUpperCase()}
                      </Tag>
                    </Space>
                  }
                  extra={
                    <Form.Item
                      name={['actions', action.id]}
                      valuePropName="checked"
                      noStyle
                    >
                      <Checkbox
                        size={'large'}
                        onChange={(event) => event.stopPropagation()}
                      />
                    </Form.Item>
                  }
                >
                  {action.description}
                </Collapse.Panel>
              ))}
            </Collapse>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

export default RoleForm
