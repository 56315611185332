import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import { Form, Row, Col, Input, Button, Typography } from 'antd'

import CLOUD_PROVIDERS from '../../../../constants/cloudProviders'
import SignOutModal from '../SignOutModal'
import {
  createCloudProvider,
  fetchCloudProvidersMe,
  deleteCloudProvider,
} from '../../../../store/actions/cloudProviders.actions'

const ResourceServer = ({
  form,
  onFinish,
  onValuesChange = () => {},
  isSuperUser = false,
  cloudProvider = null,
}) => {
  const dispatch = useDispatch()
  const [showSignOutModal, setShowSignOutModal] = useState(false)

  const signInHandler = (values) => {
    onFinish({
      name: CLOUD_PROVIDERS.resourceserver.name,
      provider: CLOUD_PROVIDERS.resourceserver.id,
      settings: {
        fullySetup: true,
        overrideUrl: values.url,
      },
    })
  }

  const signOutHandler = () => {
    dispatch(
      deleteCloudProvider({ id: cloudProvider._id, superuser: isSuperUser })
    )
  }

  let resourceServerForm
  let resourceServerInfo
  if (_.isEmpty(cloudProvider) || cloudProvider.provider !== 'resourceserver') {
    resourceServerForm = (
      <Row>
        <Col span={24}>
          <Form
            form={form}
            layout={'vertical'}
            onFinish={signInHandler}
            onValuesChange={onValuesChange}
          >
            <Row gutter={[0, 20]}>
              <Col span={24}>
                <Form.Item
                  label={'Resource Server URL'}
                  name={'url'}
                  rules={[
                    { type: 'url', message: 'Please provide a valid url' },
                    { required: true, message: 'This field is required!' },
                  ]}
                >
                  <Input placeholder={'https://resourceserver.com'} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    )
  } else {
    resourceServerInfo = (
      <Row gutter={[0, 20]}>
        <Col span={24}>
          <Row gutter={[0, 30]}>
            <Col span={24}>
              <Typography.Text>
                Connected with Resource Server @{' '}
                {cloudProvider.settings.overrideUrl}
              </Typography.Text>
            </Col>
          </Row>
          <Row gutter={[0, 20]}>
            <Button onClick={() => setShowSignOutModal(true)} danger>
              Disconnect from Resource Server
            </Button>
          </Row>
        </Col>
      </Row>
    )
  }

  return (
    <>
      <SignOutModal
        visible={showSignOutModal}
        onModalClose={() => setShowSignOutModal(false)}
        cloudProviderName={cloudProvider?.name}
        onSignOut={signOutHandler}
      />
      {resourceServerForm}
      {resourceServerInfo}
    </>
  )
}

export default ResourceServer
