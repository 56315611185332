import React from 'react'
import { Layout, Card, Row, Col } from 'antd'

import classes from './RestrictedAccessLayout.less'

const RestrictedAccessLayout = ({ children }) => {
  return (
    <Row justify={'center'} align={'middle'} className={classes.Container}>
      <Col xxl={8} xl={10} lg={12} md={14} sm={20} xs={22}>
        <Card>{children}</Card>
      </Col>
    </Row>
  )
}

export default RestrictedAccessLayout
