import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Row, Col, Form, Input, Button, Typography } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'

import classes from './SignIn.less'
import { signIn } from '../../store/actions/auth.actions'
import Logo from '../../components/Logo'

const SignIn = () => {
  const dispatch = useDispatch()
  const [authLoading] = useSelector((state) => [state.auth.loading])

  const loginSubmitHandler = (values) => {
    dispatch(signIn({ email: values.email, password: values.password }))
  }

  return (
    <>
      <Row justify={'center'} gutter={[8, 16]}>
        <Logo />
      </Row>
      <Form layout={'vertical'} size={'large'} onFinish={loginSubmitHandler}>
        <Row gutter={[8, 16]}>
          <Col xs={24} md={12}>
            <Form.Item
              name={'email'}
              normalize={(value) => value.trim()}
              rules={[
                { required: true, message: 'This field is required!' },
                { type: 'email', message: 'Please provide a valid email!' },
              ]}
            >
              <Input prefix={<UserOutlined />} placeholder={'Email'} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name={'password'}
              rules={[{ required: true, message: 'This field is required!' }]}
            >
              <Input
                prefix={<LockOutlined />}
                placeholder={'Password'}
                type={'password'}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 16]} justify={'center'}>
          <Col>
            <Button
              type={'primary'}
              shape={'round'}
              size={'large'}
              loading={authLoading}
              disabled={authLoading}
              htmlType={'submit'}
            >
              Login
            </Button>
          </Col>
          <Col>
            <Button type={'primary'} shape={'round'} size={'large'} ghost>
              <Link to={'/forgotpassword'}>Forgot password?</Link>
            </Button>
          </Col>
        </Row>
      </Form>
      <Row justify={'center'} gutter={16}>
        <Typography.Paragraph>
          FieldBox v. 0.0.2000 | Proudly made by{' '}
          <Typography.Link href={'https://www.maptext.com/'} target={'_blank'}>
            MapText, Inc
          </Typography.Link>
          .
        </Typography.Paragraph>
      </Row>
    </>
  )
}

export default SignIn
