import axiosBackendInstance from './backendInstance'

export const list = async (data, requestProps) => {
  const { data: responseData } = await axiosBackendInstance.get('/fb/device', {
    headers: {
      Authorization: `Bearer ${requestProps.token}`,
    },
    params: {
      customer: data.customer,
      uuid: data.uuid,
      name: data.name,
      limit: requestProps.limit,
      page: requestProps.page,
      orderField: requestProps.orderField,
      orderDirection: requestProps.orderDirection,
    },
  })

  return responseData
}

export const deleteDevice = async (id, requestProps) => {
  await axiosBackendInstance.delete(`/fb/device/${id}`, {
    headers: {
      Authorization: `Bearer ${requestProps.token}`,
    },
  })

  return id
}
