import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Space, Form } from 'antd'

import { createFile } from '../../../store/actions/resources.actions'

import ModifyDrawer from '../../ModifyDrawer'
import FileForm from '../FileForm'

const CreateFile = ({ visible, onDrawerClose, groupsList }) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [fileList, setFileList] = useState([])
  const [creatingFile] = useSelector((state) => [state.resources.creatingFile])

  useEffect(() => {
    if (!visible && fileList.length > 0) {
      setFileList([])
    }
  }, [visible, fileList])

  const addFileToUploadHandler = (file) => {
    setFileList((currentFileList) => [...currentFileList, file])
  }

  const removeFileFromUploadHandler = (file) => {
    setFileList((currentFileList) => {
      const removedIndex = currentFileList.indexOf(file)
      const fileListCopy = [...currentFileList]
      fileListCopy.splice(removedIndex, 1)

      return fileListCopy
    })
  }

  const createFileHandler = () => {}

  return (
    <ModifyDrawer
      title={'Upload File'}
      footer={
        <Space size={'small'}>
          <Button onClick={onDrawerClose}>Cancel</Button>
          <Button
            type={'primary'}
            onClick={() => form.submit()}
            loading={creatingFile}
            disabled={creatingFile}
          >
            Upload
          </Button>
        </Space>
      }
      onDrawerClose={onDrawerClose}
      visible={visible}
    >
      <FileForm
        form={form}
        onFinish={createFileHandler}
        groupsList={groupsList}
        fileList={fileList}
        addFileToUpload={addFileToUploadHandler}
        removeFileFromUpload={removeFileFromUploadHandler}
      />
    </ModifyDrawer>
  )
}

export default CreateFile
