import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { message } from 'antd'

import * as customersApi from '../../axios/customers'

export const fetchCustomersList = createAsyncThunk(
  'customers/list',
  async (
    { name, contactEmail, limit, page, orderField, orderDirection } = {},
    thunkAPI
  ) => {
    const response = await customersApi.list(
      { name, contactEmail },
      {
        limit,
        page,
        orderField,
        orderDirection,
        token: thunkAPI.getState().auth.token,
      }
    )

    return response
  }
)

export const fetchCustomersDetails = createAsyncThunk(
  'customers/details',
  async (id, thunkAPI) => {
    const response = await customersApi.details(
      { id },
      {
        token: thunkAPI.getState().auth.token,
      }
    )

    return response
  }
)

export const fetchCustomersMe = createAsyncThunk(
  'customers/me',
  async (data, thunkAPI) => {
    const response = await customersApi.me(null, {
      token: thunkAPI.getState().auth.token,
    })

    return response
  }
)

export const createCustomer = createAsyncThunk(
  'customers/create',
  async (data, thunkAPI) => {
    const response = await customersApi.create(data, {
      token: thunkAPI.getState().auth.token,
    })

    message.success('Customer created!')

    return response
  }
)

export const updateCustomer = createAsyncThunk(
  'customers/update',
  async ({ id, data }, thunkAPI) => {
    const response = await customersApi.update(
      { id, data },
      { token: thunkAPI.getState().auth.token }
    )

    message.success('Customer updated!')

    return response
  }
)

export const deleteCustomer = createAsyncThunk(
  'customers/deleteCustomer',
  async (id, thunkAPI) => {
    const response = await customersApi.deleteCustomer(id, {
      token: thunkAPI.getState().auth.token,
    })

    message.success('Customer deleted!')

    return response
  }
)

export const updateSettings = createAsyncThunk(
  'customers/updateSettings',
  async ({ id, data }, thunkAPI) => {
    const response = await customersApi.updateSettings(
      { id, data },
      {
        token: thunkAPI.getState().auth.token,
      }
    )

    message.success('Settings updated!')

    return response
  }
)

export const updateResourceProvider = createAsyncThunk(
  'customers/updateResourceProvider',
  async ({ id, data }, thunkAPI) => {
    const response = await customersApi.updateResourceProvider(
      { id, data },
      {
        token: thunkAPI.getState().auth.token,
      }
    )

    return response
  }
)

export const clearMe = createAction('customers/clearMe')
