import { createReducer } from '@reduxjs/toolkit'

import {
  fetchCustomersList,
  fetchCustomersDetails,
  fetchCustomersMe,
  createCustomer,
  updateCustomer,
  deleteCustomer,
  updateSettings,
  updateResourceProvider,
  clearMe,
} from '../actions/customers.actions'
import _ from 'lodash'

const initialState = {
  list: {
    loading: false,
    data: [],
    total: 0,
  },
  details: {
    loading: false,
    data: null,
  },
  me: {
    loading: false,
    data: null,
  },
  creatingCustomer: false,
  updatingCustomer: false,
  deletingCustomer: false,
  updatingSettings: false,
  updatingResourceProvider: false,
}

const customersReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchCustomersList.pending, (state, action) => {
      state.list.loading = true
      state.list.data = []
      state.list.total = 0
    })
    .addCase(fetchCustomersList.fulfilled, (state, action) => {
      state.list.loading = false
      state.list.data = action.payload.list || action.payload || []
      state.list.total = action.payload.total
    })
    .addCase(fetchCustomersDetails.pending, (state, action) => {
      state.details.loading = true
      state.details.data = null
    })
    .addCase(fetchCustomersDetails.fulfilled, (state, action) => {
      state.details.loading = false
      state.details.data = action.payload
    })
    .addCase(fetchCustomersMe.pending, (state, action) => {
      state.me.loading = true
      state.me.data = null
    })
    .addCase(fetchCustomersMe.fulfilled, (state, action) => {
      state.me.loading = false
      state.me.data = action.payload
    })
    .addCase(createCustomer.pending, (state, action) => {
      state.creatingCustomer = true
    })
    .addCase(createCustomer.fulfilled, (state, action) => {
      state.list.data.push(action.payload)

      state.creatingCustomer = false
    })
    .addCase(createCustomer.rejected, (state, action) => {
      state.creatingCustomer = false
    })
    .addCase(updateCustomer.pending, (state, action) => {
      state.updatingCustomer = true
    })
    .addCase(updateCustomer.fulfilled, (state, action) => {
      const updatedIndex = _.findIndex(state.list.data, [
        '_id',
        action.payload._id,
      ])

      // Update the list
      state.list.data[updatedIndex] = action.payload
      // Update the selected item
      state.details.data = action.payload
      // If the customer is me, update me
      if (state.me.data?._id === action.payload._id) {
        state.me.data = action.payload
      }

      state.updatingCustomer = false
    })
    .addCase(updateCustomer.rejected, (state, action) => {
      state.updatingCustomer = false
    })
    .addCase(deleteCustomer.pending, (state, action) => {
      state.deletingCustomer = true
    })
    .addCase(deleteCustomer.fulfilled, (state, action) => {
      const deletedIndex = _.findIndex(state.list.data, ['_id', action.payload])

      // Delete from the list
      state.list.data.splice(deletedIndex, 1)

      // Delete if selected
      if (state.details.data?._id === action.payload) {
        state.details.data = null
      }

      state.deletingCustomer = false
    })
    .addCase(deleteCustomer.rejected, (state, action) => {
      state.deletingCustomer = false
    })
    .addCase(updateSettings.pending, (state, action) => {
      state.updatingSettings = true
    })
    .addCase(updateSettings.fulfilled, (state, action) => {
      const updatedIndex = _.findIndex(state.list.data, [
        '_id',
        action.payload._id,
      ])

      // Update the list
      state.list.data[updatedIndex] = action.payload
      // Update the selected item
      state.details.data = action.payload
      // If the customer is me, update me
      if (state.me.data?._id === action.payload._id) {
        state.me.data = action.payload
      }

      state.updatingSettings = false
    })
    .addCase(updateSettings.rejected, (state, action) => {
      state.updatingSettings = false
    })
    .addCase(updateResourceProvider.pending, (state, action) => {
      state.updatingResourceProvider = true

      return state
    })
    .addCase(updateResourceProvider.fulfilled, (state, action) => {
      const updatedIndex = _.findIndex(state.list.data, [
        '_id',
        action.payload._id,
      ])

      // Update the list
      state.list.data[updatedIndex] = action.payload
      // Update the selected item
      state.details.data = action.payload
      // If the customer is me, update me
      if (state.me.data?._id === action.payload._id) {
        state.me.data = action.payload
      }

      state.updatingResourceProvider = false

      return state
    })
    .addCase(updateResourceProvider.rejected, (state, action) => {
      state.updatingResourceProvider = false
    })
    .addCase(clearMe, (state, action) => {
      state.me.data = null
      state.me.loading = false
    })
})

export default customersReducer
