import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

import * as APP_TYPES from '../../constants/applicationTypes'
import useAuth from '../../hooks/useAuth'
import FullPageLayout from '../FullPageLayout'
import ProtectedRoute from '../ProtectedRoute'

/* Any application */
import Profile from '../../pages/Profile'
import AuthorizeCloudProvider from '../../pages/AuthorizeCloudProvider'
import Settings from '../../pages/Settings'

/* FieldBox */
import OrganizationDashboard from '../../pages/OrganizationDashboard'
import Customers from '../../pages/Customers'
import Users from '../../pages/Users'
import Groups from '../../pages/Groups'
import Roles from '../../pages/Roles'
import Licenses from '../../pages/Licenses'
import Devices from '../../pages/Devices'

/* Resources */
import Resources from '../../pages/Resources'

/* Work Order Management */
import WorkOrderManagementDashboard from '../../pages/WorkOrderManagementDashboard'
import Projects from '../../pages/Projects'
import ProjectDetails from '../../pages/ProjectDetails'
import WorkOrders from '../../pages/WorkOrders'
import WorkOrderDetails from '../../pages/WorkOrderDetails'
import AssetTypes from '../../pages/AssetTypes'

const AuthorizedRoutes = () => {
  const { isSuperUser, hasAction, hasAnyAction, allActions } = useAuth()
  const [activeApplication] = useSelector((state) => [state.application.active])

  const applicationIndependentRoutes = [
    <Route key={'profile'} path={'/profile'} component={Profile} />,
    <Route
      key={'authorizeCloudProvider'}
      path={'/settings/authorizeCloudProvider/:cloudProvider'}
      component={AuthorizeCloudProvider}
    />,
    // <Route key={'settings'} path={'/settings/:tab/:id'} component={Settings} />,
    <ProtectedRoute
      condition={hasAnyAction([
        allActions.managecustomersettings,
        allActions.adminactions,
      ])}
      path={'/settings/:tab/:id'}
      component={Settings}
    />,
  ]

  let routes = null
  switch (activeApplication) {
    case APP_TYPES.RESOURCES:
      routes = (
        <Switch>
          {applicationIndependentRoutes}
          <Route path={'/resources'} component={Resources} />
          <Redirect to={'/resources'} />
        </Switch>
      )
      break
    case APP_TYPES.WORK_ORDER_MANAGEMENT:
      routes = (
        <Switch>
          {applicationIndependentRoutes}
          <ProtectedRoute
            condition={true}
            path={'/'}
            exact
            component={WorkOrderManagementDashboard}
          />
          <ProtectedRoute
            condition={true}
            path={'/workOrders/details/:id'}
            component={WorkOrderDetails}
          />
          <ProtectedRoute
            condition={true}
            path={'/workOrders'}
            component={WorkOrders}
          />
          <ProtectedRoute
            condition={hasAction(allActions.adminactions)}
            path={'/projects/details/:id'}
            component={ProjectDetails}
          />
          <ProtectedRoute
            condition={hasAction(allActions.adminactions)}
            path={'/projects'}
            component={Projects}
          />
          <ProtectedRoute
            condition={true}
            path={'/assetTypes'}
            component={AssetTypes}
          />
          <Redirect to={'/'} />
        </Switch>
      )
      break
    case APP_TYPES.ORGANIZATION_MANAGEMENT:
    default:
      routes = (
        <Switch>
          {applicationIndependentRoutes}
          <ProtectedRoute
            condition={hasAnyAction([
              allActions.adminactions,
              allActions.viewhome,
            ])}
            path={'/'}
            exact
            component={OrganizationDashboard}
          />
          <ProtectedRoute
            condition={isSuperUser}
            path={'/customers'}
            component={Customers}
          />
          <ProtectedRoute
            condition={hasAction(allActions.adminactions)}
            path={'/users'}
            component={Users}
          />
          <ProtectedRoute
            condition={hasAction(allActions.adminactions)}
            path={'/groups'}
            component={Groups}
          />
          <ProtectedRoute
            condition={hasAction(allActions.adminactions)}
            path={'/roles'}
            component={Roles}
          />
          <ProtectedRoute
            condition={hasAction(allActions.adminactions)}
            path={'/licenses'}
            component={Licenses}
          />
          <ProtectedRoute
            condition={hasAction(allActions.adminactions)}
            path={'/devices'}
            component={Devices}
          />
          <Redirect to={'/'} />
        </Switch>
      )
      break
  }

  return <FullPageLayout>{routes}</FullPageLayout>
}

export default AuthorizedRoutes
