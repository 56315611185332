import { createAsyncThunk, createAction } from '@reduxjs/toolkit'
import { message } from 'antd'

import { signOut } from './auth.actions'
import * as usersApi from '../../axios/users'

export const fetchUsersList = createAsyncThunk(
  'users/list',
  async (
    {
      name,
      email,
      customer,
      roles,
      limit,
      page,
      orderField,
      orderDirection,
    } = {},
    thunkAPI
  ) => {
    const response = await usersApi.list(
      {
        name,
        email,
        customer,
        roles,
      },
      {
        limit,
        page,
        orderField,
        orderDirection,
        token: thunkAPI.getState().auth.token,
      }
    )

    return response
  }
)

export const fetchUsersDetails = createAsyncThunk(
  'users/details',
  async (id, thunkAPI) => {
    const response = await usersApi.details(
      { id },
      { token: thunkAPI.getState().auth.token }
    )

    return response
  }
)

export const fetchUsersMe = createAsyncThunk(
  'users/me',
  async (data, thunkAPI) => {
    let response
    try {
      response = await usersApi.me(null, {
        token: thunkAPI.getState().auth.token,
      })
    } catch (e) {
      console.log(e.response)
      if (e.response.status === 403) {
        // Log the user out
        thunkAPI.dispatch(signOut())

        throw new Error('User not found!')
      }

      throw e
    }

    return response
  }
)

export const createUser = createAsyncThunk(
  'users/create',
  async (data, thunkAPI) => {
    const response = await usersApi.create(data, {
      token: thunkAPI.getState().auth.token,
    })

    message.success('User created!')

    return response
  }
)

export const updateUser = createAsyncThunk(
  'users/update',
  async ({ id, data }, thunkAPI) => {
    const response = await usersApi.update(
      { id, data },
      {
        token: thunkAPI.getState().auth.token,
      }
    )

    message.success('User updated!')

    return response
  }
)

export const deleteUser = createAsyncThunk(
  'users/delete',
  async (id, thunkAPI) => {
    const response = await usersApi.deleteUser(id, {
      token: thunkAPI.getState().auth.token,
    })

    message.success('User deleted!')

    return response
  }
)

export const bulkDeleteUsers = createAsyncThunk(
  'users/bulkDelete',
  async (ids, thunkAPI) => {
    const response = await usersApi.bulkDeleteUsers(ids, {
      token: thunkAPI.getState().auth.token,
    })

    message.success('Users deleted!')

    return response
  }
)

export const forgotPassword = createAsyncThunk(
  'users/forgotPassword',
  async ({ email }, thunkAPI) => {
    const response = await usersApi.forgotPassword(
      { email },
      {
        token: thunkAPI.getState().auth.token,
      }
    )

    message.success(
      ' A reset password link has been sent to your email account!'
    )

    return response
  }
)

export const changePassword = createAsyncThunk(
  'users/changePassword',
  async ({ id, prevPassword, newPassword }, thunkAPI) => {
    const response = await usersApi.changePassword(
      { id, prevPassword, newPassword },
      {
        token: thunkAPI.getState().auth.token,
      }
    )

    message.success('Password changed!')

    return response
  }
)

export const resetPassword = createAsyncThunk(
  'users/resetPassword',
  async ({ token, newPassword }, thunkAPI) => {
    const response = await usersApi.resetPassword(
      {
        token,
        newPassword,
      },
      {
        token: thunkAPI.getState().auth.token,
      }
    )

    message.success(
      'Password has been reset! You can sign in now using your new password!'
    )

    return response
  }
)

export const clearMe = createAction('users/clearMe')
