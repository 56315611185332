import React from 'react'
import { Row, Col, Empty, Spin } from 'antd'

import File from '../File'
import Folder from '../Folder'
import _ from 'lodash'

const ResourcesGridView = ({
  resources = [],
  loading = false,
  changePrefix = () => {},
  onDelete = () => {},
}) => {
  let folders = []
  let files = []
  resources.forEach((resource) => {
    if (resource.resourceType === 'file') {
      files.push(resource)
    } else {
      folders.push(resource)
    }
  })

  if (loading) {
    return (
      <Row justify={'center'} style={{ margin: '30px 0' }}>
        <Spin />
      </Row>
    )
  } else if (_.isEmpty(resources)) {
    return (
      <Empty style={{ margin: '60px 0' }} description={<span>Empty</span>} />
    )
  }

  return (
    <>
      <Row gutter={[16, 16]}>
        {folders.map((folder) => (
          <Col xs={12} sm={8} md={6} xl={4} key={folder._id}>
            <Folder
              name={folder.name}
              changePrefix={() => changePrefix(folder.resourcePath)}
              onDelete={() => onDelete(folder.resourcePath, folder._id)}
            />
          </Col>
        ))}
      </Row>
      <Row gutter={[16, 16]}>
        {files.map((file) => (
          <Col xs={12} sm={8} md={6} xl={4} key={file._id}>
            <File
              name={file.name}
              onDelete={() => onDelete(file.resourcePath, file._id)}
            />
          </Col>
        ))}
      </Row>
    </>
  )
}

export default ResourcesGridView
