import React from 'react'
import { Modal, Typography, Button } from 'antd'

const WhatIsAssetType = ({ visible, onModalClose }) => {
  return (
    <Modal
      visible={visible}
      title={'Asset Type info'}
      onCancel={onModalClose}
      footer={[
        <Button type={'primary'} onClick={onModalClose}>
          Got it!
        </Button>,
      ]}
    >
      <Typography.Paragraph>
        The asset type allows FieldBox to connect the ID of the asset in the
        work order, to the asset in the FieldMap map. The table name is the name
        of the table the asset described by the Asset Type object resides, and
        the ID Column Name is the name of the column that stores the asset's ID.
      </Typography.Paragraph>
    </Modal>
  )
}

export default WhatIsAssetType
