import React from 'react'
import { Form, Input } from 'antd'

import userPasswordRules from '../../../constants/userPasswordRules'

const ChangePasswordForm = ({ form, onFinish }) => {
  return (
    <Form layout={'vertical'} form={form} onFinish={onFinish}>
      <Form.Item
        name={'prevPassword'}
        rules={[{ required: true, message: 'This field is required!' }]}
        label={'Previous Password'}
      >
        <Input.Password type={'password'} />
      </Form.Item>
      <Form.Item
        name={'newPassword'}
        label={'New password'}
        rules={userPasswordRules}
      >
        <Input.Password type={'password'} />
      </Form.Item>
    </Form>
  )
}

export default ChangePasswordForm
