import React from 'react'
import { Tag } from 'antd'
import { BankOutlined } from '@ant-design/icons'

const CustomerTag = ({ name, ...props }) => (
  <Tag color={'magenta'} icon={<BankOutlined />} {...props}>
    {name}
  </Tag>
)

export default CustomerTag
