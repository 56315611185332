import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Modal } from 'antd'

import {
  updateWorkOrder,
  fetchWorkOrdersDetails,
} from '../../../store/actions/workOrders.actions'
import { fetchProjectsList } from '../../../store/actions/projects.actions'
import { fetchGroupsList } from '../../../store/actions/groups.actions'
import { fetchUsersList } from '../../../store/actions/users.actions'
import { fetchAssetTypesList } from '../../../store/actions/assetTypes.actions'

import CombinedForm from '../CombinedForm'
import _ from 'lodash'

const EditWorkOrder = ({
  visible,
  onModalClose,
  existingWorkOrderDetails = null,
}) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [
    updatingWorkOrder,
    workOrdersDetails,
    projectsList,
    groupsList,
    usersList,
  ] = useSelector((state) => [
    state.workOrders.updatingWorkOrder,
    state.workOrders.details,
    state.projects.list,
    state.groups.list,
    state.users.list,
  ])
  const [currentStage, setCurrentStage] = useState(0)
  const [currentProject, setCurrentProject] = useState(null)

  useEffect(() => {
    if (visible && !existingWorkOrderDetails) {
      dispatch(fetchWorkOrdersDetails(visible))
    }
  }, [dispatch, visible, existingWorkOrderDetails])

  const workOrderCustomerId =
    workOrdersDetails.data?.customer?._id || workOrdersDetails.data?.customer
  useEffect(() => {
    if (visible && workOrderCustomerId) {
      dispatch(fetchProjectsList({ customer: workOrderCustomerId }))
      dispatch(fetchGroupsList({ customer: workOrderCustomerId }))
      dispatch(fetchUsersList({ customer: workOrderCustomerId }))
      dispatch(fetchAssetTypesList({ customer: workOrderCustomerId }))
    }
  }, [visible, dispatch, workOrderCustomerId])

  const { data: workOrdersDetailsData } = workOrdersDetails
  const { data: existingWorkOrdersDetailsData } = existingWorkOrderDetails
  useEffect(() => {
    let dataToUse = workOrdersDetailsData
    if (visible && existingWorkOrdersDetailsData) {
      dataToUse = existingWorkOrdersDetailsData
    }
    if (workOrdersDetailsData || (visible && existingWorkOrdersDetailsData)) {
      setCurrentProject(dataToUse.project)

      form.setFieldsValue({
        ...dataToUse,
        startAt: moment(dataToUse.startAt),
        dueAt: moment(dataToUse.dueAt),
        project: dataToUse.project._id,
        assignedToUsers: dataToUse.assignedToUsers?.map((user) => user._id),
        assignedToGroups: dataToUse.assignedToGroups?.map((group) => group._id),
        assetsTargeted: dataToUse.assetsTargeted?.map((assetTargeted) => ({
          assetType: assetTargeted.assetType._id,
          foreignId: assetTargeted.foreignId,
        })),
      })
    }
  }, [form, workOrdersDetailsData, visible, existingWorkOrdersDetailsData])

  const formFieldsChangeHandler = (changedFields) => {
    const projectFields = changedFields.filter(
      (changedField) => changedField.name[0] === 'project'
    )

    if (projectFields.length > 0) {
      // Get the currently selected project (the whole object)
      const newProject = _.find(projectsList.data, [
        '_id',
        projectFields[0].value,
      ])
      setCurrentProject(newProject)

      // Check if the project is still the same as assetsTargeted
      const filteredAssetTypes = _.filter(
        form.getFieldValue('assetsTargeted'),
        (value) => {
          return _.includes(
            _.map(newProject.assetTypes, '_id'),
            value.assetType
          )
        }
      )

      form.setFieldsValue({
        assetsTargeted: filteredAssetTypes,
      })
    }
  }

  const updateWorkOrderHandler = (values) => {
    dispatch(
      updateWorkOrder({
        id: visible,
        data: {
          ...workOrdersDetails.data,
          ...values,
        },
      })
    ).then(() => {
      setCurrentStage(0)
      form.resetFields()
      onModalClose()
    })
  }

  return (
    <Modal
      title={'Edit Work Order'}
      width={'90%'}
      visible={visible}
      onCancel={() => {
        setCurrentStage(0)
        form.resetFields()
        onModalClose()
      }}
      footer={false}
    >
      <CombinedForm
        form={form}
        onFinish={updateWorkOrderHandler}
        onFieldsChange={formFieldsChangeHandler}
        projectsList={projectsList}
        groupsList={groupsList}
        usersList={usersList}
        projectAssetTypes={currentProject?.assetTypes}
        currentStage={currentStage}
        setCurrentStage={setCurrentStage}
        showSuperUserFields={false}
        updatingWorkOrder={updatingWorkOrder}
      />
    </Modal>
  )
}

export default EditWorkOrder
